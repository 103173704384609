import React from "react";
import { compose, withHooks, withFormik } from "enhancers";
import {
  Form,
  Field,
  TextField,
  Button,
  Avatar,
  Typography,
  Box,
  PinCode,
  Modal,
  Link,
  Divider,
} from "components";
import {
  gql,
  Yup,
  paths,
  formatPhoneNumber,
  getErrorMessage,
} from "utils/helper";
import { setToken } from "api";
import appStore from "stores/appStore";

import { ReactComponent as Logo } from "assets/image/fishyu_logo_blue.svg";
import { ReactComponent as HelpIcon } from "assets/icon/help-icon.svg";
import { ReactComponent as LeftArrow } from "assets/icon/left_arrow_dark_blue.svg";
import { ReactComponent as InfoIcon } from "assets/icon/info-icon.svg";

import styled from "styled-components/macro";

type SignInPageProps = {
  seconds?: number;
  resendOTP?: any;
  disableLoginButton?: boolean;
  phoneNumber?: string;
  refNo?: string;
  loginState?: string;
  submit?: any;
  editPhoneNumber?: () => {};
  openHelpModal?: () => {};
};

const TextButton = styled(Box)`
  :hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  }
`;

const IconButton = styled(Box)`
  :hover {
    cursor: pointer;
  }
`;

const ModalIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7f5ff;
`;

const SignInPage = (props: SignInPageProps) => (
  <>
    {props.loginState !== "phoneNumber" && (
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ background: "#FFFFFF" }}
          p={2}
        >
          <IconButton onClick={props.editPhoneNumber}>
            <LeftArrow />
          </IconButton>
          <Typography variant="body1" color="Primary Dark Blue">
            ยืนยันรหัส OTP
          </Typography>
          <IconButton onClick={props.openHelpModal}>
            <HelpIcon />
          </IconButton>
        </Box>

        <Divider />
      </Box>
    )}
    <Box mx={4}>
      <Form>
        {props.loginState === "phoneNumber" ? (
          <Box mt={14}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={10}
            >
              <Logo />
            </Box>
            <Typography
              variant="h2"
              textAlign="center"
              mb={2}
              color="Primary Dark Blue"
            >
              เข้าสู่ระบบ
            </Typography>
            <Field
              component={TextField.PhoneNumber}
              name="phoneNumber"
              placeholder="เบอร์โทรของคุณ"
              label="เบอร์โทรของคุณ"
              fullWidth
              mb={4}
            />
            <Button
              color="Primary"
              height={48}
              mb={4}
              fullWidth
              disabled={props.disableLoginButton}
              onClick={props.resendOTP}
            >
              เข้าสู่ระบบ
            </Button>
          </Box>
        ) : (
          <Box>
            <Typography variant="h2" mt={6} color="Primary Blue">
              กรอกรหัส OTP
            </Typography>
            <Typography variant="body2" color="Primary Blue">
              {`รหัส OTP ถูกส่งไปที่เบอร์ ${formatPhoneNumber(
                props.phoneNumber
              )}`}
            </Typography>
            <Typography variant="body2" color="Primary Blue" mb={4}>
              โปรดเช็คที่กล่องข้อความ
            </Typography>
            <Field
              component={PinCode}
              name="otp"
              length={6}
              display="flex"
              justifyContent="space-between"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={5}
              mb={8}
            >
              <Typography
                variant="caption"
                color="Primary Dark Blue"
                style={{
                  fontWeight: 700,
                }}
              >
                {`RefCode: ${props.refNo}`}
              </Typography>

              <TextButton
                onClick={
                  props.seconds || props.disableLoginButton
                    ? null
                    : props.resendOTP
                }
              >
                <Typography variant="body1" color="Use case Warning">
                  {props.seconds
                    ? `ขอรหัส OTP อีกครั้ง (${props.seconds})`
                    : "ขอรหัส OTP อีกครั้ง"}
                </Typography>
              </TextButton>
            </Box>
            <Button
              color="Primary"
              height={48}
              mb={4}
              fullWidth
              onClick={props.submit}
            >
              ยืนยันรหัส OTP
            </Button>

            <Button
              color="Secondary"
              height={48}
              mb={4}
              fullWidth
              onClick={props.editPhoneNumber}
            >
              แก้ไขเบอร์มือถือ
            </Button>
          </Box>
        )}
      </Form>
    </Box>
  </>
);

const API = {
  REQUEST_OTP: gql`
    mutation RequestOtp($phoneNumber: String!, $userType: String) {
      requestOtp(
        input: { phoneNumber: $phoneNumber, isMock: true, userType: $userType }
      ) {
        otpData {
          status
          token
          refno
        }
      }
    }
  `,
  VERIFY_OTP: gql`
    mutation VerifyOtp(
      $otp: String!
      $token: String!
      $phoneNumber: String!
      $userType: String!
    ) {
      verifyOtp(
        input: {
          otp: $otp
          token: $token
          phoneNumber: $phoneNumber
          userType: $userType
          isMock: true
        }
      ) {
        appUser {
          authenticationToken
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      phoneNumber: "",
      otp: "",
    }),
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      // otp: Yup.string().required("ต้องไม่เว้นว่างไว้"),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useMutation,
      useMemo,
      useParams,
      useCallback,
      useFormikContext,
      useState,
      useEffect,
    } = hooks;
    const form = useFormikContext();
    const { setErrors, values } = props;
    const [requestOtp] = useMutation(API.REQUEST_OTP);
    const [verifyOtp] = useMutation(API.VERIFY_OTP);
    const [seconds, setSeconds] = useState(0);
    const [refNo, setRefNo] = useState("");
    const [otpToken, setOtpToken] = useState(null);
    const [loginState, setLoginState] = useState("phoneNumber");

    const resendOTP = useCallback(async () => {
      const { phoneNumber } = values;
      try {
        const { data } = await requestOtp({
          variables: { phoneNumber, userType: "fisherman" },
        });
        const { refno, token } = data?.requestOtp.otpData;
        setRefNo(refno);
        setOtpToken(token);
        setSeconds(60);
        setLoginState("otp");
        setErrors({});
      } catch (e) {
        if (getErrorMessage(e) === "ERROR_INSUFFICIENT_CREDIT") {
          setErrors({
            __error__: "เครดิตหมด",
            phoneNumber: "เครดิตหมด",
          });
        } else {
          setErrors({
            __error__: "ไม่พบเบอร์โทรนี้ในระบบ",
            phoneNumber: "ไม่พบเบอร์โทรนี้ในระบบ",
          });
        }
      }
    }, [
      setSeconds,
      requestOtp,
      values,
      setRefNo,
      setOtpToken,
      setErrors,
      setLoginState,
    ]);

    const editPhoneNumber = useCallback(() => {
      setLoginState("phoneNumber");
    }, []);

    const submit = useCallback(async () => {
      const { phoneNumber, otp } = values;
      try {
        const { data } = await verifyOtp({
          variables: {
            otp,
            phoneNumber,
            token: otpToken,
            userType: "fisherman",
          },
        });
        const { appUser } = data?.verifyOtp;

        const authenticationToken = appUser.authenticationToken;
        setToken(authenticationToken);

        paths.homePath().reload();
      } catch (e) {
        if (getErrorMessage(e) === "Code is invalid.") {
          setErrors({
            __error__: "รหัส OTP ไม่ถูกต้อง",
            otp: "รหัส OTP ไม่ถูกต้อง",
          });
        }
      }
    }, [values, otpToken, setErrors, verifyOtp]);

    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [seconds]);

    const openHelpModal = useCallback(() => {
      Modal.open({
        title: (
          <Box display="flex">
            <ModalIconContainer mr={1}>
              <InfoIcon />
            </ModalIconContainer>
            <Typography variant="h3" color="Text Dark Blue">
              ติดต่อเรา
            </Typography>
          </Box>
        ),
        children: (
          <Box mb={5}>
            <Box mb={7}>
              <Typography variant="body2" color="Primary Blue">
                หากมีข้อสงสัยเกี่ยวกับการใช้งานระบบสามารถสอบถามได้ที่
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="" mb={1}>
              <Box mr={1}>
                <Typography variant="body2" color="Primary Blue">
                  โทร:
                </Typography>
              </Box>

              <Link
                href="tel:081-234-5678"
                variant="body1"
                // variant="body2"
                color="#005476"
                style={{ textDecoration: "underline" }}
              >
                081-234-5678
              </Link>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="">
              <Box mr={1}>
                <Typography variant="body2" color="Primary Blue">
                  ไลน์:
                </Typography>
              </Box>

              <Link
                href="#"
                onClick={() => (window.location.href = "line://ti/p/@fishyu")}
                variant="body1"
                color="#005476"
                style={{ textDecoration: "underline" }}
              >
                @fishyu
              </Link>
            </Box>
          </Box>
        ),
        hideCancelButton: true,
        okButtonLabel: "ปิด",
        onOk: async ({ close }: any) => {
          close();
        },
      });
    }, []);

    const disableLoginButton = form?.errors.phoneNumber;
    const { phoneNumber } = values;

    return {
      resendOTP,
      disableLoginButton,
      phoneNumber,
      refNo,
      seconds,
      loginState,
      editPhoneNumber,
      submit,
      openHelpModal,
    };
  })
);

export default enhancer(SignInPage);

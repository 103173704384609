import React from "react";
import { compose, withHooks, withStores } from "enhancers";
import {
  Box,
  Button,
  Divider,
  Link,
  Modal,
  Typography,
  InfiniteScroll,
  Hidden,
} from "components";
import { gql } from "@apollo/client";
import styled from "styled-components/macro";
import { formatDate, isEqualDate, paths, toCurrency } from "utils/helper";
import { withFormik } from "formik";
import { FishDemandCard, GroupDateCard } from "./FishDemand";

import { ReactComponent as InfoIcon } from "assets/icon/info-icon.svg";
import { ReactComponent as WaitForConfirmIcon } from "assets/icon/wait-for-confirm-icon.svg";
import { ReactComponent as WaitForShippingIcon } from "assets/icon/wait-for-shipping-icon.svg";
import { ReactComponent as SucceedIcon } from "assets/icon/succeed-icon.svg";
import { ReactComponent as ErrorIcon } from "assets/icon/error-icon.svg";

const CardContainer = styled(Box)`
  :hover {
    cursor: pointer;
  }
`;

const StatusButton = styled(Button)`
  background-color: ${(props) => (props.isActive ? "#AEDAE3" : "#fafafa")};

  :hover {
    background-color: ${(props) => (props.isActive ? "#AEDAE3" : "#fafafa")};
  }

  & span {
    color: ${(props) => (props.isActive ? "#00385A" : "#858585")};

    & div > div > svg > g > path {
      fill: ${(props) => (props.isActive ? "#00385A" : "#858585")};
    }
  }
`;

const ModalIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
`;

const HeaderContainer = styled(Box)`
  width: -webkit-fill-available;
  position: fixed;
  top: 103px;
  left: 0;
  background-color: #fff;
  padding: 0 12px;
  z-index: 1;
`;

const FooterReceivedPrice = styled(Box)`
  padding: 16px;
  background-color: #00385a;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
`;

export const BOOKING_STATUS_MAPPING = {
  waiting_for_confirm: "รอยืนยัน",
  waiting_for_shipping: "รอขนส่ง",
  waiting_for_payment: "รอโอนเงิน",
  canceled: "ยกเลิก",
  completed: "สำเร็จ",
};

const BookingTab = (props) => (
  <Box>
    <Box display="flex" flexDirection="column" px={4} pb="52px">
      <HeaderContainer>
        <Box display="flex" my={6}>
          <StatusButton
            isActive={props.bookingStatus === "waiting_for_confirm"}
            onClick={() => props.changeBookingStatus("waiting_for_confirm")}
            fullWidth
            style={{ borderRadius: 24 }}
            height={32}
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" mr={1}>
                <WaitForConfirmIcon />
              </Box>

              <Typography variant="body2">รอยืนยัน</Typography>
            </Box>
          </StatusButton>

          <StatusButton
            isActive={props.bookingStatus === "waiting_for_shipping"}
            onClick={() => props.changeBookingStatus("waiting_for_shipping")}
            fullWidth
            height={32}
            mx={3}
            style={{ borderRadius: 24 }}
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" mr={1}>
                <WaitForShippingIcon />
              </Box>

              <Typography variant="body2">รอขนส่ง</Typography>
            </Box>
          </StatusButton>

          <StatusButton
            isActive={props.bookingStatus === "success_booking"}
            onClick={() => props.changeBookingStatus("success_booking")}
            fullWidth
            style={{ borderRadius: 24 }}
            height={32}
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" mr={1}>
                <SucceedIcon />
              </Box>

              <Typography variant="body2">เสร็จสิ้น</Typography>
            </Box>
          </StatusButton>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h3" color="Primary Dark Blue">
            {props.bookingHeaderStatusText}
          </Typography>
          <Typography
            variant="caption"
            color="Use case Warning"
            display="flex"
            alignSelf="end"
            mb={1}
            style={{
              fontWeight: 700,
            }}
          >
            ทั้งหมด: {props.totalFromStatus}
          </Typography>
        </Box>

        {props.bookingStatus === "waiting_for_confirm" && (
          <Box
            display="flex"
            alignItems="center"
            py={2}
            px={4}
            mb={4}
            style={{ background: "#E7F5FF", borderRadius: 8 }}
          >
            <Box display="flex" alignSelf="center" mr={2}>
              <InfoIcon />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" color="Primary Blue">
                หากทีมงานตกลงรับซื้อจะเปลี่ยนสถานะเป็นรอขนส่ง
              </Typography>
            </Box>
          </Box>
        )}
      </HeaderContainer>

      <Box
        mt={props.bookingStatus !== "waiting_for_confirm" ? "226px" : "278px"}
        mb={props.bookingStatus === "waiting_for_shipping" ? "52px" : "0"}
      >
        <InfiniteScroll
          dataLength={props.bookingData.length}
          next={props.fetchMoreBookingData}
          hasMore={props.hasMore} // last fetch has 0 to be falses
          refreshFunction={props.handlePullToRefresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={100}
          pullDownToRefreshContent={
            <Box display="flex" justifyContent="center" mb={4}>
              <Typography variant="caption" color="Primary Blue">
                Pull down to refresh
              </Typography>
            </Box>
          }
          releaseToRefreshContent={
            <Box display="flex" justifyContent="center" mb={4}>
              <Typography variant="caption" color="Primary Blue">
                Release to refresh
              </Typography>
            </Box>
          }
        >
          {props.bookingData.map((booking, index) => (
            <Box>
              {!isEqualDate(
                props.bookingData[index - 1]?.receiveDate,
                booking?.receiveDate
              ) && (
                <GroupDateCard
                  receiveDate={booking?.receiveDate}
                  status={booking?.status}
                  isBooking={true}
                />
              )}

              <FishDemandCard
                data={booking}
                isBooking={true}
                onRowClick={props.goEditPage}
              />
            </Box>
          ))}
        </InfiniteScroll>
      </Box>
    </Box>

    <FooterReceivedPrice>
      <Box display="flex" flex={1}>
        <Typography variant="body2" color="Text White" flex={1}>
          {props.summaryText}
        </Typography>

        <Box display="flex">
          <Typography variant="body1" color="Text White" mr={2}>
            {props.totalReceivedPrice}
          </Typography>
          <Typography variant="body1" color="Text White">
            บาท
          </Typography>
        </Box>
      </Box>
    </FooterReceivedPrice>
  </Box>
);

const API = {
  FETCH_BOOKINGS: gql`
    query FETCH_BOOKINGS(
      $fishermanId: ID
      $filters: JSON
      $page: Float
      $pageSize: Float
    ) {
      bookings(
        fishermanId: $fishermanId
        filters: $filters
        page: $page
        pageSize: $pageSize
      ) {
        bookings {
          id
          code
          amount
          status
          minPrice
          maxPrice
          maxPurchasePrice
          receiveDate
          fisherman {
            id
            code
            name
          }
          fish {
            id
            name
          }
        }
        total
        totalFromStatus
        totalReceivedPrice
      }
    }
  `,
  FETCH_BOOKING: gql`
    query FETCH_BOOKING($id: ID!) {
      booking(id: $id) {
        id
        status
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useState,
      useLazyQuery,
    } = hooks;
    const { currentUser, setAmount, amount } = props;

    const [fetchBookingStatus] = useLazyQuery(API.FETCH_BOOKING, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (bookingStatus === "waiting_for_confirm") {
          if (data.booking.status === "waiting_for_confirm") {
            paths.bookingEditPath(data.booking.id).push();
          } else {
            Modal.open({
              title: (
                <Box display="flex">
                  <ModalIconContainer color="#FFE5E6" mr={1}>
                    <ErrorIcon />
                  </ModalIconContainer>
                  <Typography variant="h3" color="Text Dark Blue">
                    เกิดข้อผิดพลาด
                  </Typography>
                </Box>
              ),
              children: (
                <Box mb={5}>
                  <Box mb={7}>
                    <Typography variant="body2" color="Primary Blue">
                      การจองคิวของคุณได้รับการยืนยันจากทีมงานแล้วทำให้ไม่สามารถยกเลิกได้
                    </Typography>
                  </Box>
                  <Box mb={7}>
                    <Typography variant="body2" color="Primary Blue">
                      หากมีข้อสงสัยเกี่ยวกับการใช้งานระบบสามารถ
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent=""
                    mb={1}
                  >
                    <Box mr={1}>
                      <Typography variant="body2" color="Primary Blue">
                        โทร:
                      </Typography>
                    </Box>

                    <Link
                      href="tel:081-234-5678"
                      variant="body1"
                      // variant="body2"
                      color="#005476"
                      style={{ textDecoration: "underline" }}
                    >
                      081-234-5678
                    </Link>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="">
                    <Box mr={1}>
                      <Typography variant="body2" color="Primary Blue">
                        ไลน์:
                      </Typography>
                    </Box>

                    <Link
                      href="#"
                      onClick={() =>
                        (window.location.href = "line://ti/p/@fishyu")
                      }
                      variant="body1"
                      color="#005476"
                      style={{ textDecoration: "underline" }}
                    >
                      @fishyu
                    </Link>
                  </Box>
                </Box>
              ),
              hideCancelButton: true,
              okButtonLabel: "ตกลง",
              onOk: async ({ close }) => {
                close();
                await paths.bookingsPath().push("#booking");
              },
            });
          }
        } else {
          paths.bookingEditPath(data.booking.id).push();
        }
      },
    });

    const fishermanId = currentUser.ownerData.fisherman.id;

    const [bookingRenderData, setBookingRenderData] = useState([]);
    const [bookingStatus, setBookingStatus] = useState("waiting_for_confirm");
    const [paginate, setPaginate] = useState({ page: 1, pageSize: 15 });
    const [totalFromStatus, setTotalFromStatus] = useState(0);

    const [hasMore, setHasMore] = useState(true);

    const { loading, data, error, refetch } = useQuery(API.FETCH_BOOKINGS, {
      variables: {
        fishermanId,
        filters: { status: "waiting_for_confirm", sort: "newestDate" },
        page: 1,
        pageSize: 15,
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });

    const currencyOptions = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    };

    const bookingData = useMemo(() => {
      if (loading || error || !data) {
        return [];
      }

      return data.bookings;
    }, [loading, data, error]);

    useEffect(() => {
      if (!bookingData.bookings) {
        return;
      }

      const result = bookingData.bookings.map((booking) => {
        const {
          id,
          amount,
          receiveDate,
          maxPurchasePrice,
          status,
          minPrice,
          maxPrice,
        } = booking;

        const purchasePrice =
          minPrice === maxPrice
            ? `${toCurrency(minPrice)}`
            : `${toCurrency(minPrice)} - ${toCurrency(
                maxPrice
              )}`;

        return {
          id,
          fishName: booking.fish.name,
          purchaseVolume: toCurrency(amount, currencyOptions),
          maxPurchasePrice: toCurrency(maxPurchasePrice),
          receiveDate,
          status,
          purchasePrice,
        };
      });

      const newArray = bookingRenderData.concat(result);
      setBookingRenderData(newArray);
      setTotalFromStatus(bookingData.totalFromStatus);
      setAmount({ ...amount, bookingAmount: bookingData.total });
    }, [bookingData.bookings]);

    const fetchMoreBookingData = useCallback(async () => {
      if (bookingRenderData.length >= totalFromStatus) {
        setHasMore(false);
        return;
      }
      await refetch({
        fishermanId,
        filters: { status: bookingStatus, sort: "newestDate" },
        page: paginate.page + 1,
        pageSize: 15,
      });

      setPaginate({ page: paginate.page + 1 });
    }, [
      paginate,
      refetch,
      bookingStatus,
      fishermanId,
      bookingRenderData,
      totalFromStatus,
    ]);

    const changeBookingStatus = useCallback(
      async (state) => {
        setBookingRenderData([]);
        await refetch({
          fishermanId,
          filters: { status: state, sort: "newestDate" },
          page: 1,
          pageSize: 15,
        });

        setHasMore(true);
        setPaginate({ page: 1 });
        setBookingStatus(state);
      },
      [refetch, fishermanId]
    );

    const bookingHeaderStatusText = useMemo(() => {
      switch (bookingStatus) {
        case "waiting_for_shipping":
          return "งานรอขนส่ง";
        case "waiting_for_confirm":
          return "งานรอยืนยัน";
        case "success_booking":
          return "งานเสร็จสิ้น";
        default:
          return "งานรอขนส่ง";
      }
    }, [bookingStatus]);

    const goEditPage = useCallback(
      async (id) => await fetchBookingStatus({ variables: { id } }),
      []
    );

    const handlePullToRefresh = useCallback(async () => {
      setBookingRenderData([]);
      setHasMore(false);
      await refetch({
        fishermanId,
        filters: { status: bookingStatus, sort: "newestDate" },
        page: 1,
        pageSize: 15,
      });
      setHasMore(true);
      setPaginate({ page: 1 });
    }, [refetch, bookingStatus, fishermanId]);

    const summaryText =
      bookingStatus === "success_booking"
        ? "ได้รับเงินแล้ว"
        : "ได้รับเงินทั้งหมด (ประมาณ)";

    return {
      bookingData: bookingRenderData || [],
      fetchMoreBookingData,
      changeBookingStatus,
      bookingHeaderStatusText,
      bookingStatus,
      goEditPage,
      totalFromStatus,
      handlePullToRefresh,
      hasMore,
      totalReceivedPrice: toCurrency(
        data?.bookings?.totalReceivedPrice || 0,
        currencyOptions
      ),
      summaryText,
    };
  })
);

export default enhancer(BookingTab);

import React from "react";
import styled from "styled-components/macro";
import { compose, withHooks } from "enhancers";

import { Box, Typography, Modal, Link, Hidden } from "components";
import { ReactComponent as InfoIcon } from "assets/icon/info-icon.svg";
import { ReactComponent as HelpIcon } from "assets/icon/help-icon-white.svg";
import { ReactComponent as Logo } from "assets/image/fishyu_logo_white_2.svg";
import { ReactComponent as SearchIcon } from "assets/icon/search-icon-white.svg";

import { ReactComponent as LeftIcon } from "assets/icon/left-arrow-white.svg";
import { removeToken } from "api";

const IconButton = styled(Box)`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

const ModalIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7f5ff;
`;

const Header = (props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    style={{ background: "#00385A", position: "relatives" }}
    px={4}
    py={2.5}
  >
    <Hidden when={!props.onBack}>
      <Box
        display="flex"
        style={{
          position: "absolute",
          left: 16,
        }}
      >
        <IconButton onClick={() => props.onBack()}>
          <LeftIcon />
        </IconButton>
      </Box>
    </Hidden>

    <Hidden when={!props.title}>
      <Typography variant="body1" color="Background White">
        {props.title}
      </Typography>
    </Hidden>
    <Hidden when={props.title}>
      <Logo onClick={props.handleLogout} style={{ cursor: "pointer" }} />
    </Hidden>
    <Box
      display="flex"
      style={{
        position: "absolute",
        right: 10,
      }}
    >
      <Hidden when={!props.searchAction}>
        <IconButton onClick={props.searchAction} mr={2}>
          <SearchIcon />
        </IconButton>
      </Hidden>

      <Hidden when={!props.showHelper}>
        <IconButton onClick={props.openHelpModal}>
          <HelpIcon />
        </IconButton>
      </Hidden>
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useCallback, useState } = hooks;
    const { searchAction, title, onBack, showHelper } = props;

    const handleLogout = useCallback(() => {
      Modal.open({
        title: (
          <Typography variant="h3" color="Text Dark Blue">
            ออกจากระบบ
          </Typography>
        ),
        children: (
          <Typography variant="body2" color="Primary Blue">
            ต้องการออกจากระบบหรือไม่?
          </Typography>
        ),
        hideCancelButton: true,
        okButtonLabel: "ออกจากระบบ",
        onOk: ({ close }) => {
          try {
            close();
            removeToken();
            window.location.reload();
          } catch (e) {}
        },
      });
    }, []);

    const openHelpModal = useCallback(() => {
      Modal.open({
        title: (
          <Box display="flex">
            <ModalIconContainer mr={1}>
              <InfoIcon />
            </ModalIconContainer>
            <Typography variant="h3" color="Text Dark Blue">
              ติดต่อเรา
            </Typography>
          </Box>
        ),
        children: (
          <Box mb={5}>
            <Box mb={7}>
              <Typography variant="body2" color="Primary Blue">
                หากมีข้อสงสัยเกี่ยวกับการใช้งานระบบสามารถสอบถามได้ที่
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="" mb={1}>
              <Box mr={1}>
                <Typography variant="body2" color="Primary Blue">
                  โทร:
                </Typography>
              </Box>

              <Link
                href="tel:081-234-5678"
                variant="body1"
                // variant="body2"
                color="#005476"
                style={{ textDecoration: "underline" }}
              >
                081-234-5678
              </Link>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="">
              <Box mr={1}>
                <Typography variant="body2" color="Primary Blue">
                  ไลน์:
                </Typography>
              </Box>

              <Link
                href="#"
                onClick={() => (window.location.href = "line://ti/p/@fishyu")}
                variant="body1"
                color="#005476"
                style={{ textDecoration: "underline" }}
              >
                @fishyu
              </Link>
            </Box>
          </Box>
        ),
        hideCancelButton: true,
        okButtonLabel: "ปิด",
        onOk: async ({ close }) => {
          close();
        },
      });
    }, []);

    return {
      openHelpModal,
      searchAction,
      title,
      onBack,
      showHelper,
      handleLogout,
    };
  })
);

export default enhancer(Header);

import React from "react";
import { compose, withHooks, withStores } from "enhancers";
import {
  Box,
  TextField,
  Typography,
  Field,
  Form,
  Button,
  Divider,
  Modal,
} from "components";
import styled from "styled-components/macro";
import { withFormik } from "formik";
import { gql } from "@apollo/client";
import { isEmpty } from "lodash";

import {
  paths,
  toCurrency,
  formatDate,
  Yup,
  diffDateText,
  getErrorMessage,
} from "utils/helper";
import { ReactComponent as Warning } from "assets/icon/warning-yellow-icon.svg";
import { ReactComponent as ErrorIcon } from "assets/icon/error-icon.svg";
import { ReactComponent as Success } from "assets/icon/success-big.svg";
import Header from "./Header";
import { ModalIconContainer } from "./edit";

const TextButton = styled(Box)`
  :hover {
    cursor: pointer;
  }
`;
const ButtonContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const BookingNewPage = (props) => (
  <Box>
    {props.bookingState !== "" && (
      <Header title={props.title} onBack={props.goBack} showHelper={true} />
    )}

    <Box mx={4}>
      <Form>
        {props.bookingState === "" ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={14}
          >
            <Box mb={6}>
              <Success />
            </Box>
            <Typography variant="h3" color="Primary Dark Blue" mb={2}>
              จองคิวสำเร็จ
            </Typography>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={343}
            >
              <Typography variant="body2" color="Primary Blue" mr={1}>
                การจองคิวของคุณกำลังรอยืนยัน จากทีมงาน
              </Typography>
              <Box display="flex">
                <Typography variant="body2" color="Primary Blue" mr={1}>
                  โปรดตรวจสอบสถานะได้ที่แท็ป
                </Typography>
                <TextButton onClick={props.gotoOrder}>
                  <Typography
                    variant="body2"
                    color="Primary Blue"
                    style={{ textDecoration: "underline" }}
                  >
                    งานของฉัน
                  </Typography>
                </TextButton>
              </Box>
            </Box>
            <ButtonContainer>
              <Box mx={4}>
                <Button
                  height={48}
                  mb={4}
                  fullWidth
                  color="Primary"
                  onClick={props.goBack}
                >
                  กลับสู่หน้าหลัก
                </Button>
              </Box>
            </ButtonContainer>
          </Box>
        ) : (
          <Box>
            <Box display="flex" flexDirection="column" py={4}>
              <Box mb={4}>
                <Typography variant="h3" color="Primary Dark Blue">
                  {props.fishDemandData?.fishName}
                </Typography>
              </Box>
              {props.bookingState === "booking" && (
                <Box display="flex" justifyContent="space-between" mb={4}>
                  <Typography variant="body2" color="Primary Blue">
                    ปริมาณที่รับซื้อทั้งหมด
                  </Typography>
                  <Typography variant="body1" color="Primary Blue">
                    {props.fishDemandData?.purchaseVolume} กก.
                  </Typography>
                </Box>
              )}

              {props.bookingState === "confirm" && (
                <Box display="flex" justifyContent="space-between" mb={4}>
                  <Typography variant="body2" color="Primary Blue">
                    ปริมาณที่ต้องการ
                  </Typography>
                  <Typography variant="body1" color="Primary Blue">
                    {props.reserveVolume} กก.
                  </Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="space-between" mb={4}>
                <Typography variant="body2" color="Primary Blue">
                  รับซื้อกิโลละ
                </Typography>
                <Typography variant="body1" color="Primary Blue">
                  {props.fishDemandData?.purchasePrice} บาท
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" color="Primary Blue">
                  วันที่มารับปลา
                </Typography>
                <Typography variant="body1" color="Primary Blue">
                  {props.fishDemandData.receiveDate &&
                    `${formatDate(
                      props.fishDemandData.receiveDate,
                      "dd/MM/yyyy"
                    )} ${diffDateText(props.fishDemandData.receiveDate)}`}
                </Typography>
              </Box>
            </Box>

            {!props.isEdit ? (
              <Box display="flex" flexDirection="column">
                {props.bookingState === "booking" && (
                  <Box>
                    <Box mt={4} mb={1}>
                      <Typography variant="h3" color="Primary Dark Blue">
                        จองคิวหาปลา
                      </Typography>
                    </Box>
                    <Box mb={4}>
                      <Typography variant="body2" color="Primary Blue">
                        {`ระบุปริมาณปลาได้สูงสุด ${
                          props.maxCapacityPerBooking || 0
                        } กก. ต่อการจองคิว 1 ครั้ง`}
                      </Typography>
                    </Box>

                    <Field
                      component={TextField}
                      name="reserveVolume"
                      fullWidth
                      type="number"
                      placeholder="ปริมาณที่อยากขาย"
                      label="น้ำหนัก"
                      unit="กก."
                      mb={4}
                    />
                  </Box>
                )}
                {props.bookingState === "confirm" && (
                  <Box>
                    <Divider mb={4} />
                    <Box display="flex" justifyContent="space-between" mb={4}>
                      <Typography variant="body2" color="Primary Blue">
                        จะได้รับเงิน
                      </Typography>
                      <Typography variant="h6" color="Primary Blue">
                        {props.totalPrice} บาท
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  py={2}
                  px={4}
                  mb={4}
                  style={{ background: "#FFF8E6", borderRadius: 8 }}
                >
                  <Box display="flex" alignSelf="center" mr={2}>
                    <Warning />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="Primary Blue">
                      ถ้าหาปลาได้น้อยกว่าปริมาณที่จอง
                    </Typography>
                    <Typography variant="body2" color="Primary Blue">
                      หรือคุณภาพปลาต่ำกว่ามาตรฐาน
                    </Typography>
                    <Typography variant="body2" color="Primary Blue">
                      ราคารับซื้อปลาอาจมีการเปลี่ยนแปลง
                    </Typography>
                  </Box>
                </Box>

                {props.isError && (
                  <Box
                    display="flex"
                    alignItems="center"
                    py={2}
                    px={4}
                    style={{ background: "#FFE5E6", borderRadius: 8 }}
                  >
                    <Box display="flex" alignSelf="center" mr={2}>
                      <ErrorIcon />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2" color="Primary Blue">
                        เกิดข้อผิดพลาด
                      </Typography>
                      <Typography variant="body2" color="Primary Blue">
                        {props.errorMessage}
                      </Typography>
                      <Typography variant="body2" color="Primary Blue">
                        กรุณาแก้ไขข้อมูลและลองใหม่อีกครั้ง
                      </Typography>
                    </Box>
                  </Box>
                )}

                {props.bookingState === "booking" && (
                  <Box>
                    <ButtonContainer>
                      <Box mx={4}>
                        <Button
                          height={56}
                          mb={4}
                          fullWidth
                          color="Primary"
                          onClick={props.changeToConfirmState}
                          disabled={props.disableBookingButton}
                        >
                          จองคิว
                        </Button>
                      </Box>
                    </ButtonContainer>
                  </Box>
                )}
                {props.bookingState === "confirm" && (
                  <Box>
                    <ButtonContainer>
                      <Box mx={4}>
                        <Button
                          height={56}
                          mb={4}
                          fullWidth
                          color="Primary"
                          onClick={props.submit}
                        >
                          ยืนยันการจองคิว
                        </Button>

                        <Button
                          height={56}
                          mb={4}
                          fullWidth
                          color="Secondary"
                          onClick={props.changeToBookingState}
                        >
                          แก้ไขข้อมูล
                        </Button>
                      </Box>
                    </ButtonContainer>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                style={{
                  position: "fixed",
                  bottom: "30px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    width: "96%",
                    backgroundColor: "#667080",
                    color: "white",
                  }}
                  onClick={props.cancelOrder}
                >
                  ยกเลิกการจองคิว
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Form>
    </Box>
  </Box>
);

const API = {
  FISH_DEMANDS: gql`
    query FISH_DEMANDS($filters: JSON) {
      fishDemands(filters: $filters) {
        fishDemands {
          id
          fishName
          fishId
          purchaseVolume
          minPurchasePrice
          maxPurchasePrice
          receiveDate
        }
      }
    }
  `,
  CREATE_BOOKING: gql`
    mutation CREATE_BOOKING(
      $fishermanId: String
      $fishId: String
      $amount: Float
      $fishDemandVolume: Float
      $minPrice: Float
      $maxPrice: Float
      $maxPurchasePrice: Float
      $receiveDate: ISO8601DateTime
    ) {
      createBooking(
        input: {
          fishermanId: $fishermanId
          fishId: $fishId
          amount: $amount
          fishDemandVolume: $fishDemandVolume
          minPrice: $minPrice
          maxPrice: $maxPrice
          maxPurchasePrice: $maxPurchasePrice
          receiveDate: $receiveDate
        }
      ) {
        booking {
          code
          amount
          maxPurchasePrice
          receiveDate
          fisherman {
            id
          }
          fish {
            id
          }
        }
      }
    }
  `,
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        maxCapacityPerBooking
        minimumReservedRequired
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    validationSchema: Yup.object().shape({
      reserveVolume: Yup.lazy((value, props) => {
        if (
          props.parent.reserveVolume &&
          props.parent.purchaseVolume &&
          value > props.parent.purchaseVolume
        ) {
          return Yup.number()
            .integer()
            .moreThan(-1, "ปริมาณที่อยากขายไม่ถูกต้อง")
            .lessThan(
              props.parent.purchaseVolume + 1,
              `เกินปริมาณที่รับซื้อ (${props.parent.purchaseVolume} กก.)`
            )
            .typeError("ต้องไม่เว้นว่างเอาไว้")
            .required("ต้องไม่เว้นว่างเอาไว้");
        }
        
        if (
          props.parent.reserveVolume &&
          props.parent.purchaseVolume &&
          value < props.parent.minimumReservedRequired
        ) {
          return Yup.number()
            .integer()
            .moreThan(-1, "ปริมาณที่อยากขายไม่ถูกต้อง")
            .min(
              props.parent.minimumReservedRequired,
              `ปริมาณการจองต้องมากกว่าขั้นต่ำที่กำหนด (${props.parent.minimumReservedRequired} กก.)`
            )
            .typeError("ต้องไม่เว้นว่างเอาไว้")
            .required("ต้องไม่เว้นว่างเอาไว้");
        }

        return Yup.number()
          .integer()
          .moreThan(-1, "ปริมาณที่อยากขายไม่ถูกต้อง")
          .max(
            props.parent.maxCapacityPerBooking,
            `เกินปริมาณที่รับซื้อต่อรอบ (${props.parent.maxCapacityPerBooking} กก.)`
          )
          .positive("ปริมาณที่อยากขายไม่ถูกต้อง")
          .typeError("ต้องไม่เว้นว่างเอาไว้")
          .required("ต้องไม่เว้นว่างเอาไว้");
      }),
    }),
  }),
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useState,
      useMutation,
      useUrlParam,
      useEffect,
    } = hooks;
    const { setValues, values, errors, setFieldValue, currentUser } = props;
    const {
      reserveVolume,
      receiveDate,
      purchaseVolume,
      minPurchasePrice,
      maxPurchasePrice,
      fishId,
      maxCapacityPerBooking,
      minimumReservedRequired
    } = values;

    const fishermanId = currentUser.ownerData.fisherman.id;

    const [bookingState, setBookingState] = useState("booking");
    const [isError, setIsError] = useState(false);
    const queryParams = useUrlParam();

    const { data, refetch } = useQuery(API.FISH_DEMANDS, {
      variables: {
        filters: {
          fishId: queryParams.fishId,
          startDate: queryParams.receiveDate,
          endDate: queryParams.receiveDate,
        },
      },
    });

    const [createBooking] = useMutation(API.CREATE_BOOKING);
    const fetchSetting = useQuery(API.FETCH_SETTING, {
      fetchPolicy: "no-cache",
    });

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      if (!fetchSetting.data || fetchSetting.loading || fetchSetting.error) {
        return;
      }

      setFieldValue(
        "maxCapacityPerBooking",
        fetchSetting.data.setting.maxCapacityPerBooking
      );
      setFieldValue(
        "minimumReservedRequired",
        fetchSetting.data.setting.minimumReservedRequired
      );
    }, [
      fetchSetting.loading,
      fetchSetting.data,
      fetchSetting.error,
      setFieldValue,
    ]);

    const fishDemandData = useMemo(() => {
      const currencyOptions = {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      };

      const fishDemands = data?.fishDemands.fishDemands[0];

      const {
        fishName,
        fishId,
        purchaseVolume,
        minPurchasePrice,
        maxPurchasePrice,
        receiveDate,
      } = fishDemands || {};

      const purchasePrice =
        minPurchasePrice === maxPurchasePrice
          ? `${toCurrency(minPurchasePrice || 0)}`
          : `${toCurrency(minPurchasePrice || 0)}-${toCurrency(
              maxPurchasePrice || 0
            )}`;

      setValues({
        fishermanId,
        fishId,
        purchaseVolume,
        minPurchasePrice,
        maxPurchasePrice,
        receiveDate,
        maxCapacityPerBooking,
        minimumReservedRequired
      });

      return {
        fishName,
        fishId,
        purchaseVolume: toCurrency(purchaseVolume || 0, currencyOptions) || "0",
        purchasePrice: purchasePrice || "0",
        minPurchasePrice,
        maxPurchasePrice,
        receiveDate,
      };
    }, [data, setValues, fishermanId, maxCapacityPerBooking, minimumReservedRequired]);

    const alertNoDemandModal = useCallback(() => {
      Modal.open({
        title: (
          <Box display="flex">
            <ModalIconContainer color="#fff8e6" mr={1}>
              <Warning />
            </ModalIconContainer>
            <Typography variant="h3" color="Text Dark Blue">
              เกิดข้อผิดพลาด
            </Typography>
          </Box>
        ),
        children: (
          <Box mb={5}>
            <Box mb={2}>
              <Typography variant="body2" color="Primary Blue">
                รายการขายนี้ถูกจองคิวเต็มจำนวนแล้ว
                กรุณาทำรายการจองคิวอื่นอีกครั้ง
              </Typography>
            </Box>
          </Box>
        ),
        hideCancelButton: true,
        okButtonLabel: "กลับไปหน้าหลัก",
        onOk: ({ close }) => {
          paths.bookingsPath().push("#fish_demand");
          close();
        },
      });
    }, []);

    useEffect(() => {
      const fishDemandsLength = data?.fishDemands.fishDemands.length;
      if (fishDemandsLength === 0) {
        alertNoDemandModal();
      }
    }, [data, alertNoDemandModal]);

    const goBack = useCallback(
      () => paths.bookingsPath().push("#fish_demand"),
      []
    );

    const gotoOrder = useCallback(
      () => paths.bookingsPath().push("#booking"),
      []
    );

    const changeToConfirmState = useCallback(
      () => setBookingState("confirm"),
      []
    );

    const changeToBookingState = useCallback(
      () => setBookingState("booking"),
      []
    );

    const changeToSuccessState = useCallback(() => setBookingState(""), []);

    const title = useMemo(
      () =>
        bookingState === "booking"
          ? "รายละเอียดความต้องการซื้อ"
          : "ยืนยันการจองคิว",
      [bookingState]
    );

    const currencyOptions = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    };

    const totalPrice = useMemo(() => {
      let result = "0";
      let minTotalPrice = 0;
      let maxTotalPrice = 0;

      const { reserveVolume } = values;
      const { minPurchasePrice, maxPurchasePrice } = fishDemandData;

      if (minPurchasePrice && maxPurchasePrice && reserveVolume) {
        minTotalPrice = reserveVolume * minPurchasePrice;
        maxTotalPrice = reserveVolume * maxPurchasePrice;

        result =
          minTotalPrice === maxTotalPrice
            ? `${toCurrency(minTotalPrice)}`
            : `${toCurrency(minTotalPrice)} - ${toCurrency(
                maxTotalPrice
              )}`;
      }

      return result;
    }, [values, fishDemandData]);

    const submit = useCallback(async () => {
      try {
        await createBooking({
          variables: {
            fishermanId,
            fishId,
            amount: reserveVolume,
            fishDemandVolume: purchaseVolume,
            minPrice: minPurchasePrice,
            maxPrice: maxPurchasePrice,
            maxPurchasePrice: reserveVolume * maxPurchasePrice,
            receiveDate,
          },
        });
        setBookingState("");
      } catch (e) {
        setIsError(true);
        setErrorMessage(getErrorMessage(e));
        alertNoDemandModal();
      }
    }, [
      createBooking,
      setIsError,
      fishId,
      fishermanId,
      reserveVolume,
      purchaseVolume,
      minPurchasePrice,
      maxPurchasePrice,
      receiveDate,
      setErrorMessage,
      alertNoDemandModal,
    ]);

    const disableBookingButton = !isEmpty(errors);

    return {
      title,
      goBack,
      gotoOrder,
      fishDemandData,
      bookingState,
      changeToConfirmState,
      changeToBookingState,
      changeToSuccessState,
      totalPrice,
      disableBookingButton,
      submit,
      isError,
      maxCapacityPerBooking,
      minimumReservedRequired,
      reserveVolume,
      errorMessage,
    };
  })
);

export default enhancer(BookingNewPage);

import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import {
  Box,
  Grid,
  Field,
  TextField,
  Select,
  Typography,
  RadioGroup,
  Hidden,
} from "components";
import {
  subDistrictOptions,
  districtOptions,
  provinceOptions,
  zipCodeOptions,
} from "./helper";
import DeliveryAddressOnModal from "./DeliveryAddressOnModal";
import { isNil } from "lodash";

const AddressComponent = (props) => (
  <Box {...props}>
    <Hidden when={!props.title && !props.withTaxInfo}>
      <Box display="flex" alignItems="center" fullWidth mb={6}>
        <Typography
          component="h4"
          variant="h4"
          color="Primary/Brown"
          // fontWeight="bold"
          flex={1}
        >
          {props.title}
        </Typography>
        <Hidden when={!props.withTaxInfo || props.withoutTaxType}>
          <Field
            component={RadioGroup}
            name="taxType"
            options={props.taxOption}
            row
            className="tax_payer_type_radio_group"
            disabled={props.disabled}
          />
        </Hidden>
      </Box>
    </Hidden>

    <Grid container spacing={6}>
      <Hidden when={props.type !== "billing"}>
        <Hidden when={props.taxType !== "personal"}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="firstName"
              type="text"
              label="ชื่อ"
              fullWidth
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="lastName"
              type="text"
              label="นามสกุล"
              fullWidth
              disabled={props.disabled}
            />
          </Grid>
        </Hidden>
        <Hidden when={props.taxType !== "corporation"}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="companyName"
              type="text"
              label={props.companyNameLabel ?? "ชื่อนิติบุคคล"}
              fullWidth
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Hidden when={props.noBranch}>
              <Field
                component={TextField}
                name="branchName"
                type="text"
                label="สำนักงาน/สาขา"
                fullWidth
                disabled={props.disabled}
              />
            </Hidden>
          </Grid>
        </Hidden>
      </Hidden>

      <Hidden when={props.type !== "delivery"}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="firstName"
            type="text"
            label="ชื่อผู้รับ"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="lastName"
            type="text"
            label="นามสกุล"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden>

      <Hidden when={props.hidePhoneNumber}>
        <Grid item xs={6}>
          <Field
            component={TextField.PhoneNumber}
            name="phoneNumber"
            label="เบอร์โทรศัพท์"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden>

      <Hidden when={!props.hasGmap}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="gmapUrl"
            label="แผนที่"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden>

      <Hidden when={!props.withTaxInfo}>
        <Grid item xs={6}>
          <Field
            component={TextField.TaxId}
            name="taxId"
            label="เลขประจำตัวผู้เสียภาษี"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden>

      <Grid item xs={12}>
        <Field
          component={TextField}
          name="addressInfo"
          type="text"
          label="ที่อยู่"
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="subDistrict"
          type="text"
          label="แขวง/ตำบล"
          options={subDistrictOptions}
          transformDisplay={props.transformDisplaySubDistrict}
          freeSolo
          fullWidth
          fuse
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="district"
          type="text"
          label="เขต/อำเภอ"
          options={districtOptions}
          freeSolo
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="province"
          type="text"
          label="จังหวัด"
          options={provinceOptions}
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="zipcode"
          type="text"
          label="รหัสไปรษณีย์"
          options={zipCodeOptions}
          freeSolo
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Hidden when={props.hideRemark}>
        <Grid item xs={12}>
          <Field
            component={TextField}
            name="remark"
            type="text"
            label="หมายเหตุ"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden>
    </Grid>
  </Box>
);

const enhancer = compose(
  defaultProps({
    taxOption: [
      { label: "บุคคลธรรมดา", value: "personal" },
      { label: "นิติบุคคล", value: "corporation" },
    ],
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
      addressType: props.type,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      taxId: null,
      addressInfo: null,
      subDistrict: null,
      district: null,
      province: null,
      zipcode: null,
      // remark: null,
      ...props.field.value,
      taxType: props.field.value?.taxType
        ? props.field.value?.taxType
        : "personal",
    }),
  }),
  withHooks((props, hooks) => {
    const { useCallback, useEffect } = hooks;
    const {
      form,
      field,
      values,
      setFieldValue,
      setValues,
      type,
      disabled,
      ...rest
    } = props;

    const outerSetFieldValue = form.setFieldValue;
    const outerFieldName = field.name;
    const onChange = useCallback(
      (address) => {
        outerSetFieldValue(outerFieldName, address);
      },
      [outerSetFieldValue, outerFieldName]
    );

    useEffect(() => {
      if (!`${values.subDistrict}`.includes(" » ")) {
        onChange(values);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(values)]);

    const currentSubDistrict = values.subDistrict;

    useEffect(() => {
      if (currentSubDistrict) {
        const [
          subDistrict,
          district,
          province,
          zipcode,
        ] = currentSubDistrict.split(" » ");

        const hasNestedField = !isNil(zipcode);
        if (hasNestedField) {
          setFieldValue("subDistrict", subDistrict);
          setFieldValue("district", district);
          setFieldValue("province", province);
          setFieldValue("zipcode", zipcode);
        }
      }
    }, [currentSubDistrict, setFieldValue]);

    const transformDisplaySubDistrict = useCallback((label) => {
      return "XXXXX-" + label;
    }, []);

    const taxType = values.taxType;
    const withTaxInfo = type === "billing";

    return {
      taxType,
      withTaxInfo,
      transformDisplaySubDistrict,
      disabled,
      type,
      ...rest,
    };
  })
);

const Address = enhancer(AddressComponent);
const DeliveryOnModal = enhancer(DeliveryAddressOnModal);

Address.Billing = defaultProps({ type: "billing" })(Address);
Address.Delivery = defaultProps({ type: "delivery" })(Address);
Address.DeliveryOnModal = defaultProps({ type: "delivery" })(DeliveryOnModal);

export default Address;

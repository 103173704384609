import React, { useRef } from "react";
import styled from "styled-components/macro";
import { compose, withHooks } from "enhancers";

import { gql, isEqualDate, paths, toCurrency } from "utils/helper";

import {
  Box,
  Typography,
  Hidden,
  Button,
  Grid,
  Field,
  TextField,
  Select,
  DatePicker,
  InfiniteScroll,
} from "components";
import { withFormik } from "formik";
import { Drawer } from "@material-ui/core";
import { FishDemandCard, GroupDateCard } from "./FishDemand";
import { omit, pickBy, identity } from "lodash";

import { ReactComponent as CloseIcon } from "assets/icon/close-icon.svg";

const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const IconButton = styled(Box)`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;
  margin-left: 16px;

  :hover {
    cursor: pointer;
  }
`;

const FilterContainer = styled(Box)`
  width: -webkit-fill-available;
  position: fixed;
  top: 102px;
  left: 0;
  background-color: #fff;
  padding: 0 12px;
  z-index: 1;
`;

const HeaderContainer = styled(FilterContainer)`
  top: 278px;
`;

const FilterContent = (props) => (
  <Box px={4} py={6}>
    <Box display="flex" justifyContent="space-between" mb={6}>
      <Typography variant="h3" color="Text Dark Blue">
        ตัวกรองเพิ่มเติม
      </Typography>
      <ResetFilterButton onClick={props.clearFilter}>
        <Typography variant="body2" color="Use case Warning">
          รีเซ็ตตัวกรอง
        </Typography>
      </ResetFilterButton>
    </Box>

    <Grid container spacing={0}>
      <Grid item xs={12} mb={4}>
        <Field
          component={TextField}
          name="minPurchasePrice"
          type="number"
          label="ราคารับซื้อต่ำสุด"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="orderBy"
          type="text"
          label="เรียงตาม"
          options={props.orderByOptions}
          fullWidth
        />
      </Grid>
      <Typography variant="body2" color="Text Dark Blue" mb={4}>
        วันที่ต้องส่งปลา
      </Typography>
      <Grid item display="flex" flexDirection="column" xs={12} mb={6}>
        <Box display="flex">
          <Grid item flex={1}>
            <Field
              component={DatePicker}
              name="startDate"
              placeholder="ตั้งแต่วันที่"
              fullWidth
              markedDates={props.existedDemandDates}
            />
          </Grid>

          <Grid item display="flex" alignSelf="center" mx={2}>
            <Typography variant="body2" color="Text Dark Blue">
              -
            </Typography>
          </Grid>

          <Grid item flex={1}>
            <Field
              component={DatePicker}
              name="endDate"
              placeholder="ถึงวันที่"
              fullWidth
              markedDates={props.existedDemandDates}
            />
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} mb={4}>
        <Button color="Primary" onClick={props.onFilter} fullWidth height={56}>
          ยืนยัน
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button color="Secondary" onClick={props.onClose} fullWidth height={56}>
          ปิด
        </Button>
      </Grid>
    </Grid>
  </Box>
);

const SearchingPage = (props) => (
  <Box>
    <FilterContainer top="102">
      <Box display="flex" flexDirection="column" py={6} flex={1}>
        <Box display="flex" alignItems="center" mb={4}>
          <Field
            component={TextField}
            name="fishName"
            fullWidth
            placeholder="ระบุชื่อปลาเพื่อค้นหา"
            startIcon="search"
            type="text"
          />
          <IconButton onClick={props.clearSearch}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex">
          <Button className="FilterButton" onClick={props.onOpenFilter}>
            {`ตัวกรอง `}
            {props.filterAmount && `(${props.filterAmount})`}
          </Button>
        </Box>
      </Box>
    </FilterContainer>

    <Hidden
      when={props.fishDemandData.length === 0 || props.isShowNotFoundData}
    >
      <Box mt="322px">
        <HeaderContainer>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h3" color="Primary Dark Blue">
              รายการขายปลา
            </Typography>
            <Typography
              variant="caption"
              color="Use case Warning"
              display="flex"
              alignSelf="end"
              mb={1}
              style={{
                fontWeight: 700,
              }}
            >
              ทั้งหมด: {props.fishDemandAmount}
            </Typography>
          </Box>
        </HeaderContainer>

        <Box>
          <InfiniteScroll
            dataLength={props.fishDemandData.length}
            next={props.fetchMoreFishDemandData}
            hasMore={props.hasMore}
          >
            {props.fishDemandData.map((booking, index) => (
              <Box>
                {!isEqualDate(
                  props.fishDemandData[index - 1]?.receiveDate,
                  booking?.receiveDate
                ) && (
                  <GroupDateCard
                    receiveDate={booking?.receiveDate}
                    status={booking?.status}
                  />
                )}

                <Box>
                  <FishDemandCard data={booking} onRowClick={props.goNewPage} />
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>
    </Hidden>

    <Hidden when={!props.isShowNotFoundData}>
      <Box display="flex" alignItems="center" flexDirection="column" mt="322px">
        <Typography variant="body2" color="Primary Blue">
          ไม่พบรายการที่ต้องการ
        </Typography>
        <Typography variant="body2" color="Primary Blue">
          กรุณาตรวจสอบค่าตัวกรองเพิ่มเติม
        </Typography>
      </Box>
    </Hidden>

    <Drawer
      anchor="bottom"
      open={props.showFilter}
      onClose={props.onCloseFilter}
    >
      <FilterContent
        orderByOptions={props.orderByOptions}
        onClose={props.onCloseFilter}
        clearFilter={props.clearFilter}
        onFilter={props.onFilter}
        existedDemandDates={props.existedDemandDates}
      />
    </Drawer>
  </Box>
);

const API = {
  DEMAND_INFO: gql`
    query FISHERMAN_DASHBOARD_DEMAND_INFO {
      fishermanDashboardInfo {
        existedDemandDates
      }
    }
  `,
  FISH_DEMANDS: gql`
    query FISH_DEMANDS($filters: JSON, $page: Float, $pageSize: Float) {
      fishDemands(filters: $filters, page: $page, pageSize: $pageSize) {
        fishDemands {
          id
          fishName
          fishId
          purchaseVolume
          minPurchasePrice
          maxPurchasePrice
          receiveDate
        }
        total
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      orderBy: "priceDesc",
      endDate: null,
      startDate: null,
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useEffect, useState, useLazyQuery, useQuery } = hooks;
    const { setValues, setFieldValue, values, showSearchFilter } = props;

    const time = useRef(null);
    const [rawData, setRawData] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filterAmount, setFilterAmount] = useState(1);
    const [refetchNeeded, setRefetchNeeded] = useState(false);
    const [fishDemandAmount, setFishDemandAmount] = useState(0);
    const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 });
    const [fishDemandRenderData, setFishDemandRenderData] = useState([]);

    const [hasMore, setHasMore] = useState(true);

    const [fetchFishDemands, { refetch }] = useLazyQuery(API.FISH_DEMANDS, {
      variables: {
        filters: { orderBy: "priceDesc" },
        page: 1,
        pageSize: 15,
      },
    });

    useEffect(() => {
      async function fetchFishDemand() {
        await fetchFishDemands();
        setRefetchNeeded(true);
      }

      fetchFishDemand();
    }, [refetchNeeded, fetchFishDemands, setRefetchNeeded]);

    const refetchFishDemand = useCallback(
      async (params) => {
        if (refetchNeeded) {
          const response = await refetch({
            ...params,
          });

          setRefetchNeeded(false);
          return response;
        }
      },
      [refetchNeeded, refetch]
    );

    const fishDemandData = useMemo(async () => {
      const fishDemands = rawData?.fishDemands.map((demand) => {
        const {
          id,
          fishName,
          fishId,
          purchaseVolume,
          minPurchasePrice,
          maxPurchasePrice,
          receiveDate,
        } = demand;

        const currencyOptions = {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        };

        const purchasePrice =
          minPurchasePrice === maxPurchasePrice
            ? `${toCurrency(minPurchasePrice, currencyOptions)}`
            : `${toCurrency(minPurchasePrice, currencyOptions)} - ${toCurrency(
                maxPurchasePrice,
                currencyOptions
              )}`;

        return {
          id,
          fishName,
          fishId,
          purchaseVolume: toCurrency(purchaseVolume, currencyOptions),
          purchasePrice,
          minPurchasePrice,
          maxPurchasePrice,
          receiveDate,
        };
      });

      setFishDemandRenderData(fishDemands);
      setFishDemandAmount(rawData?.total || 0);
      return fishDemands;
    }, [rawData, setFishDemandRenderData, setFishDemandAmount]);

    const onFilter = useCallback(async () => {
      const response = await refetchFishDemand({
        filters: { ...values, fishId: values.fish },
        page: 1,
        pageSize: 15,
      });

      setRawData(response.data?.fishDemands);

      const filteredNullValues = pickBy(values, identity);

      const amount =
        Object.keys(omit(filteredNullValues, ["fishName"])).length || 0;

      setFilterAmount(amount);
      setShowFilter(false);
      setIsFiltered(true);
      setPaginate({ page: 1 });
    }, [
      values,
      refetchFishDemand,
      setShowFilter,
      setFilterAmount,
      setPaginate,
    ]);

    useEffect(() => {
      time.current && clearTimeout(time.current);
      time.current = setTimeout(async () => {
        if (values.fishName) {
          const response = await refetchFishDemand({
            filters: values,
            page: 1,
            pageSize: 15,
          });

          setRawData(response.data.fishDemands);
        }
      }, 1000);
    }, [values.fishName, setRawData]);

    const onOpenFilter = useCallback(() => setShowFilter(true), []);
    const onCloseFilter = useCallback(() => {
      setShowFilter(false);
    }, []);

    const clearFilter = useCallback(() => {
      setValues({
        fishName: null,
        minPurchasePrice: null,
        orderBy: "priceDesc",
        startDate: null,
        endDate: null,
      });

      setFilterAmount(1);
      setPaginate({ page: 1 });
    }, [setValues, setPaginate, setFishDemandRenderData]);

    const orderByOptions = useMemo(() => {
      return [
        { label: "ราคาเรียงจากมากไปน้อย", value: "priceDesc" },
        { label: "จำนวนที่ต้องการจากมากไปน้อย", value: "amountDesc" },
        { label: "จำนวนที่ต้องการจากน้อยไปมาก", value: "amountAsc" },
        { label: "วันที่ต้องการจากล่าสุด", value: "newestDate" },
        { label: "วันที่ต้องการจากเก่าสุด", value: "oldestDate" },
      ];
    }, []);

    const fetchMoreFishDemandData = useCallback(async () => {
      if (fishDemandRenderData.length >= fishDemandAmount) {
        setHasMore(false);
        return;
      }

      const response = await refetchFishDemand({
        filters: values,
        page: paginate.page + 1,
        pageSize: 10,
      });
      const currencyOptions = {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      };

      const moreFishDemand = response?.data.fishDemands.fishDemands.map(
        (item) => {
          const purchasePrice =
            item.minPurchasePrice === item.maxPurchasePrice
              ? `${toCurrency(item.minPurchasePrice, currencyOptions)}`
              : `${toCurrency(
                  item.minPurchasePrice,
                  currencyOptions
                )} - ${toCurrency(item.maxPurchasePrice, currencyOptions)}`;
          return {
            ...item,
            purchasePrice,
            purchaseVolume: toCurrency(item.purchaseVolume, currencyOptions),
          };
        }
      );
      const newArray = fishDemandRenderData.concat(moreFishDemand);
      setPaginate({ page: paginate.page + 1 });
      setFishDemandRenderData(newArray);
    }, [
      paginate,
      refetchFishDemand,
      values,
      fishDemandRenderData,
      fishDemandAmount,
    ]);

    const isShowNotFoundData = useMemo(() => {
      if (
        (isFiltered || values.fishName) &&
        rawData?.fishDemands.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    }, [isFiltered, values.fishName, rawData]);

    const clearSearch = useCallback(() => {
      setFieldValue("fishName", "");
    }, [setFieldValue]);

    const goNewPage = useCallback(
      (data) => {
        const { fishId, receiveDate } = data;

        const params = {
          fishId,
          receiveDate,
        };

        paths.bookingNewPath(params).push();
      },

      []
    );


    const {
      loading: demandInfoLoading,
      data: demandInfo,
      error: demandInfoError,
    } = useQuery(API.DEMAND_INFO, {
      fetchPolicy: "no-cache",
    });

    const existedDemandDates = useMemo(() => {
      const existedDemandDates = demandInfo?.fishermanDashboardInfo?.existedDemandDates || []
      return [...existedDemandDates]
    }, [demandInfo])

    return {
      showFilter,
      onOpenFilter,
      onCloseFilter,
      onFilter,
      fishDemandData: fishDemandRenderData || [],
      orderByOptions,
      clearFilter,
      filterAmount,
      showSearchFilter,
      fetchMoreFishDemandData,
      isShowNotFoundData,
      fishDemandAmount,
      clearSearch,
      goNewPage,
      hasMore,
      existedDemandDates,
    };
  })
);

export default enhancer(SearchingPage);

import React from "react";
import { compose, withHooks } from "enhancers";
import { Box, Hidden, Typography, InfiniteScroll } from "components";
import styled from "styled-components/macro";
import {
  diffDateText,
  formatDate,
  gql,
  isEqualDate,
  paths,
  toCurrency,
} from "utils/helper";
import { ReactComponent as RightIcon } from "assets/icon/arrow_right-icon-yellow.svg";
import { ReactComponent as CalendarIcon } from "assets/icon/calendar-icon.svg";

import { withFormik } from "formik";
import { BOOKING_STATUS_MAPPING } from "./Booking";
import SearchingPage from "./SearchingPage";

const CardContainer = styled(Box)`
  background-color: #fafafa;
  border-radius: 4px;

  :hover {
    cursor: pointer;
  }
`;

const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const StatusTag = styled(Box)`
  display: flex;
  padding: 1px 8px;
  border-radius: 4px;
  height: 20px;
  align-items: center;
  background-color: ${(props) => props.bgColor};

  & span {
    font-weight: 700;
  }
`;

const HeaderContainer = styled(Box)`
  width: -webkit-fill-available;
  position: fixed;
  left: 0;
  background-color: #fff;
  z-index: 1;
`;

const BOOKING_STATUS_COLOR_MAPPING = {
  waiting_for_payment: "#FBBA00",
  canceled: "#D82332",
  completed: "#00AD82",
};

export const GroupDateCard = (props) => (
  <Box display="flex" alignItems="center" mb={2}>
    <Box display="flex">
      <CalendarIcon />
    </Box>
    <Box>
      <Typography
        variant="caption"
        color="Text Dark Blue"
        ml={1}
        style={{ fontWeight: 700 }}
      >
        <Hidden when={props.isBooking}>
          {`${formatDate(props.receiveDate, "dd/MM/yyyy")} ${diffDateText(
            props.receiveDate
          )}`}
        </Hidden>

        <Hidden when={!props.isBooking}>
          {["waiting_for_confirm", "waiting_for_shipping"].includes(
            props.status
          )
            ? `${formatDate(props.receiveDate, "dd/MM/yyyy")} ${diffDateText(
                props.receiveDate
              )}`
            : `${formatDate(props.receiveDate, "dd/MM/yyyy")}`}
        </Hidden>
      </Typography>
    </Box>
  </Box>
);

export const FishDemandCard = (props) => (
  <CardContainer
    onClick={() =>
      props.onRowClick(props.isBooking ? props.data.id : props.data)
    }
    p={2}
    mb={2}
  >
    <Box display="flex" alignItems="center">
      <Typography flex="1" variant="body1" color="Text Dark Blue">
        {props.data?.fishName}
      </Typography>
      <RightIcon />
    </Box>

    <Hidden when={props.isBooking}>
      <Box display="flex" alignItems="center">
        <Typography flex="1" variant="caption" color="Primary Blue">
          {`จำนวน ${props.data?.purchaseVolume} กก.`}
        </Typography>
        <Box display="flex">
          <Typography variant="body1" color="Text Dark Blue" mr={1.5}>
            {props.data?.purchasePrice}
          </Typography>
          <Box display="flex" alignSelf="end">
            <Typography variant="caption" color="Primary Blue" mb={0.5}>
              บาท/กก.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Hidden>
    <Hidden when={!props.isBooking}>
      <Box>
        {props.data?.status === "waiting_for_confirm" && (
          <Box display="flex" alignItems="center">
            <Typography flex="1" variant="caption" color="Primary Blue">
              {`จำนวน ${props.data?.purchaseVolume} กก.`}
            </Typography>
            <Box display="flex">
              <Typography variant="body1" color="Text Dark Blue" mr={1.5}>
                {props.data?.purchasePrice}
              </Typography>
              <Box display="flex" alignSelf="end">
                <Typography variant="caption" color="Primary Blue" mb={0.5}>
                  บาท/กก.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {props.data?.status === "waiting_for_shipping" && (
          <Box display="flex" alignItems="center">
            <Typography flex="1" variant="caption" color="Primary Blue">
              {`จำนวน ${props.data?.maxPurchasePrice} บาท`}
            </Typography>
            <Box display="flex">
              <Typography variant="body1" color="Text Dark Blue" mr={1.5}>
                {props.data?.purchaseVolume}
              </Typography>
              <Box display="flex" alignSelf="end">
                <Typography variant="caption" color="Primary Blue" mb={0.5}>
                  กก.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {props.data?.status !== "waiting_for_confirm" &&
          props.data?.status !== "waiting_for_shipping" && (
            <Box display="flex" alignItems="center">
              <Box display="flex" flex="1">
                <StatusTag
                  color="white"
                  bgColor={BOOKING_STATUS_COLOR_MAPPING[props.data?.status]}
                >
                  <Typography variant="caption">
                    {BOOKING_STATUS_MAPPING[props.data?.status]}
                  </Typography>
                </StatusTag>
              </Box>

              <Box display="flex">
                <Typography variant="body1" color="Text Dark Blue" mr={1.5}>
                  {props.data?.purchaseVolume}
                </Typography>
                <Box display="flex" alignSelf="end">
                  <Typography variant="caption" color="Primary Blue" mb={0.5}>
                    กก.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
      </Box>
    </Hidden>
  </CardContainer>
);

const FishDemandTab = (props) => (
  <Box>
    <Box display="flex" flexDirection="column" px={4}>
      <Hidden when={!props.showSearchFilter}>
        <SearchingPage />
      </Hidden>
      <Hidden when={props.showSearchFilter}>
        <Box mt="102.5px">
          <HeaderContainer
            display="flex"
            justifyContent="space-between"
            py={4}
            px={3}
          >
            <Typography variant="h3" color="Primary Dark Blue">
              รายการขายปลา
            </Typography>
            <Typography
              variant="caption"
              color="Use case Warning"
              display="flex"
              alignSelf="end"
              mb={1}
              style={{
                fontWeight: 700,
              }}
            >
              ทั้งหมด: {props.total}
            </Typography>
          </HeaderContainer>

          <Box mt="64px">
            <InfiniteScroll
              dataLength={props.fishDemandData.length}
              next={props.fetchMoreFishDemandData}
              hasMore={props.hasMore}
              refreshFunction={props.handlePullToRefresh}
              // scrollThreshold="300px"
              pullDownToRefresh
              pullDownToRefreshThreshold={100}
              pullDownToRefreshContent={
                <Box display="flex" justifyContent="center" mb={4}>
                  <Typography variant="caption" color="Primary Blue">
                    Pull down to refresh
                  </Typography>
                </Box>
              }
              releaseToRefreshContent={
                <Box display="flex" justifyContent="center" mb={4}>
                  <Typography variant="caption" color="Primary Blue">
                    Release to refresh
                  </Typography>
                </Box>
              }
            >
              {props.fishDemandData.map((booking, index) => (
                <Box>
                  {!isEqualDate(
                    props.fishDemandData[index - 1]?.receiveDate,
                    booking?.receiveDate
                  ) && (
                    <GroupDateCard
                      receiveDate={booking?.receiveDate}
                      status={booking?.status}
                    />
                  )}

                  <Box>
                    <FishDemandCard
                      data={booking}
                      onRowClick={props.goNewPage}
                    />
                  </Box>
                </Box>
              ))}
            </InfiniteScroll>
          </Box>
        </Box>
      </Hidden>
    </Box>
  </Box>
);

const API = {
  FISH_DEMANDS: gql`
    query FISH_DEMANDS($filters: JSON, $page: Float, $pageSize: Float) {
      fishDemands(filters: $filters, page: $page, pageSize: $pageSize) {
        fishDemands {
          id
          fishName
          fishId
          purchaseVolume
          minPurchasePrice
          maxPurchasePrice
          receiveDate
        }
        total
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      orderBy: "priceDesc",
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useState, useEffect } = hooks;
    const { setValues, values, showSearchFilter, setAmount, amount } = props;

    const { loading, data, error, refetch } = useQuery(API.FISH_DEMANDS, {
      variables: {
        filters: { orderBy: "priceDesc" },
        page: 1,
        pageSize: 15,
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });

    const [fishDemandRenderData, setFishDemandRenderData] = useState([]);
    const [paginate, setPaginate] = useState({ page: 1, pageSize: 15 });
    const [showFilter, setShowFilter] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [total, setTotal] = useState(0);

    const [hasMore, setHasMore] = useState(true);

    const fishDemandData = useMemo(() => {
      if (loading || error || !data) {
        return [];
      }

      return data.fishDemands;
    }, [loading, data, error]);

    useEffect(() => {
      if (!fishDemandData.fishDemands) {
        return;
      }

      const fishDemands = fishDemandData.fishDemands.map((demand) => {
        const {
          id,
          fishName,
          fishId,
          purchaseVolume,
          minPurchasePrice,
          maxPurchasePrice,
          receiveDate,
        } = demand;

        const currencyOptions = {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        };

        const purchasePrice =
          minPurchasePrice === maxPurchasePrice
            ? `${toCurrency(minPurchasePrice)}`
            : `${toCurrency(minPurchasePrice)} - ${toCurrency(
                maxPurchasePrice
              )}`;

        return {
          id,
          fishName,
          fishId,
          purchaseVolume: toCurrency(purchaseVolume, currencyOptions),
          purchasePrice,
          minPurchasePrice,
          maxPurchasePrice,
          receiveDate,
        };
      });

      const newArray = fishDemandRenderData.concat(fishDemands);
      setFishDemandRenderData(newArray);
      setTotal(fishDemandData.total);
      setAmount({ ...amount, fishDemandAmount: fishDemandData.total });
    }, [fishDemandData.fishDemands]);

    const onFilter = useCallback(async () => {
      await refetch({
        filters: { ...values, fishId: values.fish },
        page: 1,
        pageSize: 15,
      });

      setIsFiltered(true);
      setShowFilter(false);
      setPaginate({ page: 1 });
    }, [values, refetch, setShowFilter, setIsFiltered, setPaginate]);

    const fetchMoreFishDemandData = useCallback(async () => {
      if (fishDemandRenderData.length >= total) {
        setHasMore(false);
        return;
      }

      await refetch({
        filters: values,
        page: paginate.page + 1,
        pageSize: 15,
      });

      setPaginate({ page: paginate.page + 1 });
    }, [
      paginate,
      refetch,
      values,
      total,
      setHasMore,
      fishDemandRenderData.length,
    ]);

    const onOpenFilter = useCallback(() => setShowFilter(true), []);

    const onCloseFilter = useCallback(() => setShowFilter(false), []);

    const clearFilter = useCallback(async () => {
      setValues({
        fishName: null,
        minPurchasePrice: null,
        orderBy: "priceDesc",
        startDate: null,
        endDate: null,
      });

      await refetch({
        filters: { orderBy: "priceDesc" },
        page: 1,
        pageSize: 15,
      });

      setIsFiltered(false);
      setShowFilter(false);
      setPaginate({ page: 1 });
    }, [setValues, refetch, setIsFiltered, setPaginate]);

    const orderByOptions = useMemo(() => {
      return [
        { label: "ราคาเรียงจากมากไปน้อย", value: "priceDesc" },
        { label: "จำนวนที่ต้องการจากมากไปน้อย", value: "amountDesc" },
        { label: "จำนวนที่ต้องการจากน้อยไปมาก", value: "amountAsc" },
        { label: "วันที่ต้องการจากล่าสุด", value: "newestDate" },
        { label: "วันที่ต้องการจากเก่าสุด", value: "oldestDate" },
      ];
    }, []);

    const goNewPage = useCallback(
      (data) => {
        const { fishId, receiveDate } = data;

        const params = {
          fishId,
          receiveDate,
        };

        paths.bookingNewPath(params).push();
      },

      []
    );

    const handlePullToRefresh = useCallback(async () => {
      setFishDemandRenderData([]);
      setHasMore(false);
      await refetch({
        filters: { orderBy: "priceDesc" },
        page: 1,
        pageSize: 15,
      });
      setHasMore(true);
      setPaginate({ page: 1 });
    }, [refetch]);

    return {
      showFilter,
      onOpenFilter,
      onCloseFilter,
      onFilter,
      fishDemandData: fishDemandRenderData || [],
      fetchMoreFishDemandData,
      orderByOptions,
      goNewPage,
      clearFilter,
      isFiltered,
      showSearchFilter,
      handlePullToRefresh,
      total,
      hasMore,
    };
  })
);

export default enhancer(FishDemandTab);

import React from "react";
import { compose, withHooks } from "enhancers";
import {
  Box,
  TextField,
  Typography,
  Field,
  Form,
  Modal,
  Button,
  Divider,
  FieldArray,
  Link,
  Hidden,
} from "components";
import styled from "styled-components/macro";
import { withFormik } from "formik";
import { gql } from "@apollo/client";

import {
  paths,
  getErrorMessage,
  notifyError,
  toCurrency,
  formatDate,
  diffDateText,
  formatPhoneNumber,
} from "utils/helper";
import { ReactComponent as Warning } from "assets/icon/warning-yellow-icon.svg";
import { ReactComponent as WaitForConfirmIcon } from "assets/icon/wait-for-confirm-icon.svg";
import { ReactComponent as WaitForShippingIcon } from "assets/icon/wait-for-shipping-icon.svg";

import { ReactComponent as ErrorIcon } from "assets/icon/error-icon.svg";

import FileImage from "components/advance/FileImage";

import { BOOKING_STATUS_MAPPING } from "./Booking";
import Header from "./Header";

const IconButton = styled(Box)`
  :hover {
    cursor: pointer;
  }
`;

const ButtonContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const ModalIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
`;

export const BookingEditPage = (props) => (
  <Box>
    {props.bookingState !== "" && (
      <Header title={props.title} onBack={props.goBack} />
    )}

    <Box mx={4}>
      <Form>
        <Box display="flex" flexDirection="column" py={4}>
          <Box mb={4}>
            <Typography variant="h3" color="Primary Dark Blue">
              {props.bookingData.fishName}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="body2" color="Primary Blue">
              สถานะงาน
            </Typography>
            <Box display="flex" alignItems="center">
              <Box display="flex" mr={2}>
                {props.bookingData.status === "waiting_for_confirm" && (
                  <WaitForConfirmIcon />
                )}

                {props.bookingData.status === "waiting_for_shipping" && (
                  <WaitForShippingIcon />
                )}
              </Box>
              <Typography variant="body1" color="Primary Dark Blue">
                {BOOKING_STATUS_MAPPING[props.bookingData.status ?? {}]}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="body2" color="Primary Blue">
              ปริมาณที่ต้องการหา
            </Typography>
            <Typography variant="body1" color="Primary Dark Blue">
              {props.bookingData.purchaseVolume} กก.
            </Typography>
          </Box>

          <Hidden
            when={
              !["waiting_for_payment", "completed"].includes(
                props.bookingData.status
              )
            }
          >
            <Box display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="body2" color="Primary Blue">
                ปริมาณที่ส่งมอบ
              </Typography>
              <Typography variant="body1" color="Primary Dark Blue">
                {props.bookingData.receiveVolume} กก.
              </Typography>
            </Box>
          </Hidden>

          <Box display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="body2" color="Primary Blue">
              รับซื้อกิโลละ
            </Typography>
            <Typography variant="body1" color="Primary Dark Blue">
              {props.bookingData.purchasePrice} บาท
            </Typography>
          </Box>
          {props.bookingData.status === "canceled" && (
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" color="Primary Blue">
                วันที่ยกเลิก
              </Typography>
              <Typography variant="body1" color="Primary Dark Blue">
                {props.bookingData.cancelBookingDate}
              </Typography>
            </Box>
          )}
          {["waiting_for_confirm", "waiting_for_shipping"].includes(
            props.bookingData.status
          ) && (
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" color="Primary Blue">
                วันที่มารับปลา
              </Typography>
              <Typography variant="body1" color="Primary Dark Blue">
                {/* formatDate(props.bookingData.receiveDate, "dd/MM/yyyy") */}
                {`${formatDate(
                  props.bookingData.receiveDate,
                  "dd/MM/yyyy"
                )} ${diffDateText(props.bookingData.receiveDate)}`}
              </Typography>
            </Box>
          )}
          {["waiting_for_payment", "completed"].includes(
            props.bookingData.status
          ) && (
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" color="Primary Blue">
                วันที่ส่งมอบ
              </Typography>
              <Typography variant="body1" color="Primary Dark Blue">
                {formatDate(props.bookingData.receiveDate, "dd/MM/yyyy")}
              </Typography>
            </Box>
          )}
        </Box>

        {props.bookingData.status === "waiting_for_shipping" && (
          <Box>
            <Divider mb={4} />
            <Box display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="body2" color="Primary Blue">
                จะได้รับเงิน
              </Typography>
              <Typography variant="body1" color="Primary Dark Blue">
                {props.bookingData.totalPrice} บาท
              </Typography>
            </Box>
          </Box>
        )}

        {props.bookingData.status === "completed" && (
          <Box>
            <Box>
              <Divider mb={4} />
              <Box display="flex" justifyContent="space-between" mb={4}>
                <Typography variant="body2" color="Primary Blue">
                  วันที่โอนเงิน
                </Typography>
                <Typography variant="body1" color="Primary Dark Blue">
                  {props.bookingData.paymentDate}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={10}>
                <Typography variant="body2" color="Primary Blue">
                  จะได้รับเงิน
                </Typography>
                <Typography variant="body1" color="Primary Blue">
                  {props.bookingData.totalPrice} บาท
                </Typography>
              </Box>
            </Box>

            <Box>
              <Typography variant="h3" color="Primary Blue" mb={4}>
                หลักฐานการโอนเงิน
              </Typography>
              <Box display="flex" justifyContent="center" mb={3}>
                <FieldArray component={FileImage} name="images" />
              </Box>
            </Box>
          </Box>
        )}

        {props.bookingData.status === "waiting_for_shipping" && (
          <Box>
            <Box
              display="flex"
              alignItems="center"
              py={2}
              px={4}
              mb={4}
              style={{ background: "#FFF8E6", borderRadius: 8 }}
            >
              <Box display="flex" alignSelf="center" mr={2}>
                <Warning />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="body2" color="Primary Blue">
                  ถ้าหาปลาได้น้อยกว่าปริมาณที่จอง
                </Typography>
                <Typography variant="body2" color="Primary Blue">
                  หรือคุณภาพปลาต่ำกว่ามาตรฐาน
                </Typography>
                <Typography variant="body2" color="Primary Blue">
                  ราคารับซื้อปลาอาจมีการเปลี่ยนแปลง
                </Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" mt={10}>
              <Box mb={4}>
                <Typography variant="h3" color="Primary Dark Blue">
                  ข้อมูลพนักงานขนส่ง
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={4}>
                <Typography variant="body2" color="Primary Blue">
                  ชื่อ
                </Typography>
                <Typography variant="body1" color="Primary Dark Blue">
                  {props.shipperData.name || "-"}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={4}>
                <Typography variant="body2" color="Primary Blue">
                  เบอร์โทรศัพท์
                </Typography>
                <Link
                  href={`tel:${formatPhoneNumber(
                    props.shipperData.phoneNumber
                  )}`}
                  variant="body1"
                  color="#005476"
                  style={{ textDecoration: "underline" }}
                >
                  {formatPhoneNumber(props.shipperData.phoneNumber)}
                </Link>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={4}>
                <Typography variant="body2" color="Primary Blue">
                  เลขทะเบียนรถ
                </Typography>
                <Typography variant="body1" color="Primary Dark Blue">
                  {props.shipperData.licensePlateNumber || "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {props.bookingData.status === "waiting_for_confirm" && (
          <Box>
            <Box>
              <Divider my={4} />
              <Box display="flex" justifyContent="space-between" mb={4}>
                <Typography variant="body2" color="Primary Blue">
                  จะได้รับเงิน
                </Typography>
                <Typography variant="body1" color="Primary Dark Blue">
                  {props.bookingData.totalPrice} บาท
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              py={2}
              px={4}
              mb={4}
              style={{ background: "#FFF8E6", borderRadius: 8 }}
            >
              <Box display="flex" alignSelf="center" mr={2}>
                <Warning />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="body2" color="Primary Blue">
                  ถ้าต้องการแก้ไขปริมาณปลา
                </Typography>
                <Typography variant="body2" color="Primary Blue">
                  กรุณาการยกเลิกการจองคิวและทำรายการ
                </Typography>
                <Typography variant="body2" color="Primary Blue">
                  ใหม่เพื่อรอการพิจารณาอีกครั้ง
                </Typography>
              </Box>
            </Box>
            <ButtonContainer>
              <Box mx={4}>
                <Button
                  height={48}
                  mb={4}
                  fullWidth
                  color="primary"
                  onClick={props.cancelOrder}
                >
                  ยกเลิกการจองคิว
                </Button>
              </Box>
            </ButtonContainer>
          </Box>
        )}
      </Form>
    </Box>
  </Box>
);

const API = {
  FETCH_BOOKING: gql`
    query FETCH_BOOKING($id: ID!) {
      booking(id: $id) {
        id
        code
        amount
        status
        minPrice
        maxPrice
        maxPurchasePrice
        receiveDate
        updatedAt
        fisherman {
          id
          code
          name
        }
        fish {
          id
          name
        }
        orderSupplies {
          images
          updatedAt
          order {
            shipper {
              name
              phoneNumber
              licensePlateNumber
            }
            orderDemands {
              status
              gradeAFishDeliveredWeight
              gradeBFishDeliveredWeight
              gradeCFishDeliveredWeight
              gradeDFishDeliveredWeight
            }
          }
        }
      }
    }
  `,
  CANCEL_BOOKING: gql`
    mutation CANCEL_BOOKING($id: ID!) {
      cancelBooking(input: { id: $id }) {
        booking {
          id
          code
          status
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useMutation,
      useParams,
    } = hooks;
    const { setValues, values } = props;
    const { bookingData, shipperData } = values;
    const { id } = useParams();
    const { loading, data, error, refetch } = useQuery(API.FETCH_BOOKING, {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    });
    const [cancelBooking] = useMutation(API.CANCEL_BOOKING);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const booking = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.bookingsPath().push();
        return null;
      }

      return data.booking;
    }, [loading, data, error]);

    useEffect(() => {
      if (booking) {
        const {
          fish,
          amount,
          minPrice,
          maxPrice,
          receiveDate,
          status,
          orderSupplies,
          updatedAt: cancelBookingDate,
        } = booking;

        const orderSupply = orderSupplies?.[0] || {};

        const currencyOptions = {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        };
        
        const purchasePrice =
          minPrice === maxPrice
            ? `${toCurrency(minPrice)}`
            : `${toCurrency(minPrice)} - ${toCurrency(
                maxPrice
              )}`;

        let totalPrice = "0";
        let minTotalPrice = 0;
        let maxTotalPrice = 0;

        minTotalPrice = amount * minPrice;
        maxTotalPrice = amount * maxPrice;

        totalPrice =
          minTotalPrice === maxTotalPrice
            ? `${toCurrency(minTotalPrice)}`
            : `${toCurrency(minTotalPrice)} - ${toCurrency(
                maxTotalPrice
              )}`;

        let receiveVolume = 0;

        if (orderSupply?.order?.orderDemands[0]) {
          const {
            gradeAFishDeliveredWeight,
            gradeBFishDeliveredWeight,
            gradeCFishDeliveredWeight,
            gradeDFishDeliveredWeight,
          } = orderSupply?.order?.orderDemands[0];

          const volumeGradeA = gradeAFishDeliveredWeight || 0;
          const volumeGradeB = gradeBFishDeliveredWeight || 0;
          const volumeGradeC = gradeCFishDeliveredWeight || 0;
          const volumeGradeD = gradeDFishDeliveredWeight || 0;

          receiveVolume =
            volumeGradeA + volumeGradeB + volumeGradeC + volumeGradeD;
        }

        const bookingData = {
          fishId: fish.id,
          fishName: fish.name,
          purchaseVolume: toCurrency(amount || 0, currencyOptions),
          purchasePrice,
          receiveDate,
          minPurchasePrice: minPrice || 0,
          maxPurchasePrice: maxPrice || 0,
          totalPrice,
          status,
          cancelBookingDate: formatDate(cancelBookingDate, "dd/MM/yyyy"),
          paymentDate: formatDate(orderSupply?.updatedAt, "dd/MM/yyyy"),
          receiveVolume,
        };

        setValues({
          shipperData: orderSupply.order?.shipper,
          images: orderSupply.images,
          bookingData: bookingData,
        });
      }
    }, [setValues, booking]);

    const goBack = useCallback(() => paths.bookingsPath().push("#booking"), []);
    const cancelOrder = useCallback(() => {
      Modal.open({
        title: (
          <Box display="flex">
            <ModalIconContainer color="#fff8e6" mr={1}>
              <Warning />
            </ModalIconContainer>
            <Typography variant="h3" color="Text Dark Blue">
              ยกเลิกการจองคิว
            </Typography>
          </Box>
        ),
        children: (
          <Box mb={5}>
            <Box mb={2}>
              <Typography variant="body2" color="Primary Blue">
                {`ยกเลิกการจองคิวหา${bookingData?.fishName} ${bookingData?.purchaseVolume} กก. มูลค่า ${bookingData?.totalPrice} บาท`}
              </Typography>
            </Box>
            <Box mb={6}>
              <Typography variant="body1" color="Use case Danger">
                คุณอาจจะไม่เจอออเดอร์นี้อีกหากการจองขายเต็มแล้ว
              </Typography>
            </Box>
          </Box>
        ),
        cancelButtonLabel: "ปิด",
        okButtonLabel: "ยกเลิกการจองคิว",
        onOk: async ({ close }) => {
          try {
            close();
            await cancelBooking({
              variables: { id },
            });
            await paths.cancelBookingPath(id).push();
          } catch (e) {
            if (
              getErrorMessage(e) ===
              "ไม่ยกเลิกการจองคิวได้ เนื่องจากสถานะไม่ใช่รอยืนยัน"
            ) {
              Modal.open({
                title: (
                  <Box display="flex">
                    <ModalIconContainer color="#FFE5E6" mr={1}>
                      <ErrorIcon />
                    </ModalIconContainer>
                    <Typography variant="h3" color="Text Dark Blue">
                      เกิดข้อผิดพลาด
                    </Typography>
                  </Box>
                ),
                children: (
                  <Box mb={5}>
                    <Box mb={7}>
                      <Typography variant="body2" color="Primary Blue">
                        การจองคิวของคุณได้รับการยืนยันจากทีมงานแล้วทำให้ไม่สามารถยกเลิกได้
                      </Typography>
                    </Box>
                    <Box mb={7}>
                      <Typography variant="body2" color="Primary Blue">
                        หากมีข้อสงสัยเกี่ยวกับการใช้งานระบบสามารถ
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent=""
                      mb={1}
                    >
                      <Box mr={1}>
                        <Typography variant="body2" color="Primary Blue">
                          โทร:
                        </Typography>
                      </Box>

                      <Link
                        href="tel:081-234-5678"
                        variant="body1"
                        // variant="body2"
                        color="#005476"
                        style={{ textDecoration: "underline" }}
                      >
                        081-234-5678
                      </Link>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="">
                      <Box mr={1}>
                        <Typography variant="body2" color="Primary Blue">
                          ไลน์:
                        </Typography>
                      </Box>

                      <Link
                        href="#"
                        onClick={() =>
                          (window.location.href = "line://ti/p/@fishyu")
                        }
                        variant="body1"
                        color="#005476"
                        style={{ textDecoration: "underline" }}
                      >
                        @fishyu
                      </Link>
                    </Box>
                  </Box>
                ),
                hideCancelButton: true,
                okButtonLabel: "ตกลง",
                onOk: async ({ close }) => {
                  close();
                  await paths.bookingsPath().push("#booking");
                },
              });
            } else {
              notifyError(e);
            }
          }
        },
      });
    }, [bookingData]);

    return {
      title: "รายละเอียดงาน",
      goBack,
      isEdit: true,
      cancelOrder,
      bookingData: bookingData || {},
      shipperData,
    };
  })
);

export default enhancer(BookingEditPage);

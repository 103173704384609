import React from "react";
import { withFormik } from "formik";
import styled from "styled-components/macro";
import { compose, withHooks, withStores } from "enhancers";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Form,
  Modal,
  Button,
  Link,
  Divider,
} from "components";
import { gql, paths } from "utils/helper";
import FishDemandTab from "./FishDemand";
import BookingTab from "./Booking";

import { ReactComponent as InfoIcon } from "assets/icon/info-icon.svg";

import { ReactComponent as DescriptionIcon } from "assets/icon/description-icon.svg";
import { ReactComponent as WorkIcon } from "assets/icon/work-icon.svg";
import Header from "./Header";

const AntTabs = styled(Tabs)({
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected > span > div > p": {
    color: "#00385A !important",
  },
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected > span > div > div > svg > g > path": {
    fill: "#00385A !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#00385A !important",
  },
  "& .MuiTabs-flexContainer": {
    borderBottom: "inset",
  },

  "& .MuiTab-root": {
    maxWidth: "unset !important",
  },
});

const IconButton = styled(Box)`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

const ModalIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7f5ff;
`;

const HeaderContainer = styled(Box)`
  position: fixed;
  width: -webkit-fill-available;
  background-color: #fff;
  z-index: 1;
`;

const BookingIndexPage = (props) => (
  <Form>
    <Box>
      <HeaderContainer>
        <Header
          searchAction={
            props.hashTag === "#fish_demand"
              ? props.toggleShowSearchFilter
              : null
          }
          showHelper={true}
          onBack={
            props.showSearchFilter && props.hashTag === "#fish_demand"
              ? props.toggleShowSearchFilter
              : null
          }
        />
        <Box px={3}>
          <AntTabs value={props.tabState} onChange={props.changeTabState}>
            <Tab
              className="FullWidthTab"
              label={
                <Box display="flex" alignItems="center">
                  <Box display="flex" mr={2.5}>
                    <DescriptionIcon />
                  </Box>

                  <Typography variant="body2" color="Text Placeholder">
                    {`รายการขาย (${props.amount.fishDemandAmount})`}
                  </Typography>
                </Box>
              }
            />
            <Tab
              className="FullWidthTab"
              label={
                <Box display="flex" alignItems="center">
                  <Box display="flex" mr={2.5}>
                    <WorkIcon />
                  </Box>
                  <Typography variant="body2" color="Text Placeholder">
                    {`งานของฉัน (${props.amount.bookingAmount})`}
                  </Typography>
                </Box>
              }
            />
          </AntTabs>
        </Box>
      </HeaderContainer>
      <Box>
        {props.hashTag === "#fish_demand" && (
          <FishDemandTab
            showSearchFilter={props.showSearchFilter}
            amount={props.amount}
            setAmount={props.setAmount}
          />
        )}
        {props.hashTag === "#booking" && (
          <BookingTab amount={props.amount} setAmount={props.setAmount} />
        )}
      </Box>
    </Box>
  </Form>
);

const API = {
  FISH_DEMANDS: gql`
    query FISH_DEMANDS($filters: JSON) {
      fishDemands(filters: $filters) {
        total
      }
    }
  `,
  FETCH_BOOKINGS: gql`
    query FETCH_BOOKINGS($fishermanId: ID) {
      bookings(fishermanId: $fishermanId) {
        total
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props, hooks) => {
    const {
      useCallback,
      useEffect,
      useState,
      useLocation,
      useQuery,
      useMemo,
    } = hooks;
    const { currentUser } = props;

    const fishermanId = currentUser.ownerData.fisherman.id;

    const { data: bookingData } = useQuery(API.FETCH_BOOKINGS, {
      variables: {
        fishermanId,
      },
    });

    const { data: fishDemandData } = useQuery(API.FISH_DEMANDS, {
      variables: { filters: {} },
    });

    const [tabState, setTabState] = useState(0);
    const [showSearchFilter, setShowSearchFilter] = useState(false);
    const [amount, setAmount] = useState({
      fishDemandAmount: 0,
      bookingAmount: 0,
    });

    const location = useLocation();
    const hashTag = location.hash;

    useEffect(() => {
      if (!bookingData || !fishDemandData) {
        return;
      }

      setAmount({
        bookingAmount: bookingData.bookings.total,
        fishDemandAmount: fishDemandData.fishDemands.total,
      });
    }, [bookingData, fishDemandData]);

    useEffect(() => {
      if (!hashTag) {
        paths.bookingsPath().push("#fish_demand");
      }
    }, []);

    useEffect(() => {
      switch (hashTag) {
        case "#fish_demand":
          return setTabState(0);
        case "#booking":
          return setTabState(1);
        default:
          return setTabState(0);
      }
    }, [hashTag]);

    const changeTabState = useCallback((e, value) => {
      switch (value) {
        case 0:
          return paths.bookingsPath().push("#fish_demand");
        case 1:
          return paths.bookingsPath().push("#booking");
        default:
          return paths.bookingsPath().push("#fish_demand");
      }
    }, []);

    const openHelpModal = useCallback(() => {
      Modal.open({
        title: (
          <Box display="flex">
            <ModalIconContainer mr={1}>
              <InfoIcon />
            </ModalIconContainer>
            <Typography variant="h3" color="Text Dark Blue">
              ติดต่อเรา
            </Typography>
          </Box>
        ),
        children: (
          <Box mb={5}>
            <Box mb={7}>
              <Typography variant="body2" color="Primary Blue">
                หากมีข้อสงสัยเกี่ยวกับการใช้งานระบบสามารถสอบถามได้ที่
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="" mb={1}>
              <Box mr={1}>
                <Typography variant="body2" color="Primary Blue">
                  โทร:
                </Typography>
              </Box>

              <Link
                href="tel:081-234-5678"
                variant="body1"
                color="#005476"
                style={{ textDecoration: "underline" }}
              >
                081-234-5678
              </Link>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="">
              <Box mr={1}>
                <Typography variant="body2" color="Primary Blue">
                  ไลน์:
                </Typography>
              </Box>

              <Link
                href="#"
                onClick={() => (window.location.href = "line://ti/p/@fishyu")}
                variant="body1"
                color="#005476"
                style={{ textDecoration: "underline" }}
              >
                @fishyu
              </Link>
            </Box>
          </Box>
        ),
        hideCancelButton: true,
        okButtonLabel: "ปิด",
        onOk: async ({ close }) => {
          close();
        },
      });
    }, []);

    const toggleShowSearchFilter = useCallback(() => {
      setShowSearchFilter(!showSearchFilter);
    }, [setShowSearchFilter, showSearchFilter]);

    return {
      tabState,
      changeTabState,
      hashTag,
      openHelpModal,
      toggleShowSearchFilter,
      showSearchFilter,
      amount,
      setAmount,
    };
  })
);

export default enhancer(BookingIndexPage);

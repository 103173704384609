import { compose, defaultProps, withHooks } from "enhancers";
import { Box } from "components";
import { DatePicker as FormikMuiDatePicker } from "formik-material-ui-pickers";
import { format, parseISO } from "date-fns";
import Badge from "@material-ui/core/Badge";

import { ReactComponent as DatePickerIcon } from "assets/icon/datepicker_icon.svg";
import { includes, map } from "lodash";

const DatePicker = (props) => (
  <Box width={props.width} position="relative">
    <FormikMuiDatePicker {...props} />
    <Box position="absolute" top={16} right={12}>
      <DatePickerIcon />
    </Box>
  </Box>
);

const enhancer = compose(
  defaultProps({ format: "dd/MM/yyyy", placeholder: "00/00/0000" }),
  withHooks((props, hooks) => {
    const { useCallback } = hooks;
    const { markedDates = [], ...restProps } = props;

    const markedDateStrs = map(markedDates, (d) =>
      d instanceof Date
        ? format(d, "dd/MM/yyyy")
        : format(parseISO(d), "dd/MM/yyyy")
    );

    const renderDay = useCallback(
      (date, selectedDate, isInCurrentMonth, dayComponent) => {
        const hasDot =
          isInCurrentMonth &&
          includes(markedDateStrs, format(date, "dd/MM/yyyy"));
        return (
          <Badge
            color="secondary"
            variant="dot"
            overlap="circle"
            invisible={!hasDot}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {dayComponent}
          </Badge>
        );
      },
      [markedDateStrs]
    );

    return {
      ...restProps,
      renderDay,
      labelFunc: (date, invalidLabel) =>
        props.field.value ? format(date, props.format) : "",
    };
  })
);

export default enhancer(DatePicker);

import qs from "qs";
import { last } from "lodash";
import { history } from "components/common/BrowserRouter";

// prettier-ignore
export class Path extends String {
  newTab = () => {
    window.open(this.toString(), '__blank')
  };

  push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath??''}`);
    }
  };

  reload = () => {
    window.location.href = this.toString()
  }

  makePath = (path: Function): Function => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);
        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  page404Path             = this.makePath(() => `404`)
  page500Path             = this.makePath(() => `500`)

  signInPath              = this.makePath(() => `auth/sign-in`)

  bookingsPath            = this.makePath(() => `bookings`)
  bookingNewPath          = this.makePath((id: any) => `bookings/new`)
  bookingEditPath         = this.makePath((id: any) => `bookings/${id}/edit`)
  cancelBookingPath       = this.makePath((id: any) => `bookings/${id}/cancel`)

  homePath                = this.makePath(() => ``)
}

export default new Path();

import React from "react";
import { TextField } from "formik-material-ui";
import { compose, withHooks, defaultProps } from "enhancers";
import MaskedInput from "react-text-mask";
import NumberFormat from "react-number-format";
import { replace } from "lodash";
import { Yup } from "utils/helper";
import Typography from "./Typography";
import { InputAdornment } from "@material-ui/core";
import { IconButton } from "components";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

import { ReactComponent as SearchIcon } from "assets/icon/search-icon-blue.svg";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { inputRef, onChange, value, ...other } = props;
  return (
    <NumberFormat
      {...other}
      value={value ? value : ""}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue ?? null,
          },
        });
      }}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      type="text"
      thousandSeparator
    />
  );
});

const makeTextFieldMask = (mask) => (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      onChange={(e) => {
        const temp = e.target.value;
        e.target.value = replace(temp, /[^\d]/g, "");
        props.onChange(e);
        e.target.value = temp;
      }}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
    />
  );
};

const INPUT_PROPS = {
  phoneNumber: {
    inputComponent: makeTextFieldMask([
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]),
  },
  taxId: {
    inputComponent: makeTextFieldMask([
      /\d/,
      " ",
      /\d/,
      /\d/,
      " ",
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
    ]),
  },
  number: {
    inputComponent: NumberFormatCustom,
  },
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useState, useCallback } = hooks;
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => {
      setShowPassword(!showPassword);
    }, [showPassword]);

    const InputProps = useMemo(() => {
      return {
        ...INPUT_PROPS[props.type],
        startAdornment: props.startIcon === "search" && (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: props.unit ? (
          <Typography color="Text/Dark Grey">{props.unit}</Typography>
        ) : props.type === "password" ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )}
            </IconButton>
          </InputAdornment>
        ) : undefined,
      };
    }, [props.type, props.unit, handleClickShowPassword, showPassword]);

    const type = useMemo(() => (showPassword ? "text" : props.type), [
      showPassword,
      props.type,
    ]);

    const inputProps = useMemo(() => {
      if (props.textAlign) {
        return { style: { textAlign: props.textAlign } };
      }
    }, [props.textAlign]);

    return {
      inputProps,
      ...props,
      InputProps: InputProps,
      type,
    };
  })
);

const _TextField = enhancer(TextField);

_TextField.Email = defaultProps({ type: "email" })(_TextField);
_TextField.Email.validationSchema = Yup.string()
  .nullable()
  .email("รูปแบบอีเมลไม่ถูกต้อง");

_TextField.PhoneNumber = defaultProps({ type: "phoneNumber" })(_TextField);
_TextField.PhoneNumber.validationSchema = Yup.string()
  .nullable()
  .transform((value) => (value === "" ? null : value))
  .matches(/^\d{10}$/, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง");

_TextField.TaxId = defaultProps({ type: "taxId" })(_TextField);
_TextField.TaxId.validationSchema = Yup.string()
  .nullable()
  .matches(/^\d{13}$/, "รูปแบบเลขประจำตัวผู้เสียภาษีไม่ถูกต้อง");

export default _TextField;

import React from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "components";
import PinField from "react-pin-field";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { get } from "lodash";

import { compose, defaultProps, withHooks } from "enhancers";

const defaultStyle = {
  border: "1px solid #EEEEEE",
  borderRadius: "6px",
  fontSize: "32px",
  textAlign: "center",
  height: "64px",
  width: "50px",
  minWidth: "0px",
  outline: "none",
  color: "#00385A",
  fontWeight: "700",
};

const errorStyle = {
  border: "1px solid #FF0000",
  borderRadius: "6px",
  fontSize: "32px",
  textAlign: "center",
  height: "64px",
  width: "50px",
  minWidth: "0px",
  outline: "none",
  color: "#667080",
  fontWeight: "700",
};

const PinCode = ({ layoutProps, editorProps, error }) => (
  <>
    <Box>
      <Box {...layoutProps}>
        <PinField
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          {...editorProps}
        />
      </Box>
      <Typography variant="body2" color="#FF0000" mt={2}>
        {error}
      </Typography>
    </Box>
  </>
);
const enhancer = compose(
  defaultProps({
    theme: "snow",
  }),
  withHooks((props, hooks) => {
    const { form, field, length, className, style, ...rest } = props;
    const { useMemo, useCallback, useState } = hooks;

    const [pinStyle, setPinStyle] = useState(defaultStyle);
    const { setFieldValue } = form;
    const { name, value } = field;

    const layoutProps = rest;

    const error = useMemo(() => {
      const error = get(form.errors, field.name);
      if (error !== undefined) {
        setPinStyle(errorStyle);
      } else {
        setPinStyle(defaultStyle);
      }
      return error;
    }, [form, field, setPinStyle]);

    const onChange = useCallback(
      (value) => {
        setFieldValue(name, value);
      },
      [setFieldValue, name]
    );

    const editorProps = useMemo(() => {
      return {
        onChange,
        length,
        className,
        style: style ?? pinStyle,
        ...rest,
      };
    }, [length, onChange, className, rest, pinStyle, style]);

    return {
      layoutProps,
      editorProps,
      error,
    };
  })
);

export default enhancer(PinCode);

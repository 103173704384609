import Typography from "@material-ui/core/Typography";
import { compose, withHooks } from "enhancers";

export const COLOR = {
  "Primary Dark Blue": "#00385A",
  "Primary Blue": "#005476",

  "Secondary Blue": "#AEDAE3",

  "Text Dark Blue": "#00385A",
  "Text Grey": "#858585",
  "Text Disable": "#B7B7B7",
  "Text Placeholder": "#D3D3D3",
  "Text White": "#FFFFFF",

  "Line Grey": "#EEEEEE",

  "Background Overlay": "#1B1B1B",
  "Background Grey": "#FAFAFA",
  "Background White": "#FFFFFF",

  "Use case Info": "#2399FC",
  "Use case Danger": "#D82332",
  "Use case Warning": "#FBBA00",
  "Use case Success": "#00AD82",
  "Use case Light Info": "#E7F5FF",
  "Use case Light Danger": "#FDE7E9",
  "Use case Light Warning": "#FFF8E6",
  "Use case Light Success": "#E6F9F3",
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { color, style, ...rest } = props;
    const { useMemo } = hooks;

    const customColor = COLOR[color];
    const customStyle = useMemo(() => {
      return customColor ? { ...style, color: customColor } : customColor;
    }, [customColor, style]);
    const customRestProps = customColor ? rest : { color, ...rest };

    return {
      ...customRestProps,
      style: customStyle,
    };
  })
);

export default enhancer(Typography);

import React from "react";
import { compose, withHooks } from "enhancers";
import { Box, Typography, Modal, Button } from "components";
import styled from "styled-components/macro";
import { withFormik } from "formik";
import { gql } from "@apollo/client";
import { Avatar as MuiAvatar } from "components";
import { ReactComponent as Cancel } from "assets/icon/error-big.svg";

import { notifySuccess, paths } from "utils/helper";

const Avatar = styled(MuiAvatar)`
  width: 100%;
  height: 100%;
  max-height: 600px;
  max-width: 400px;
`;

const ButtonContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const CancelBookingPage = (props) => (
  <Box display="flex" flexDirection="column" alignItems="center" mt={14}>
    <Box mb={6}>
      <Cancel />
    </Box>
    <Typography variant="h3" color="Primary Dark Blue" mb={2}>
      ยกเลิกการจองคิว
    </Typography>

    <Box display="flex" flexDirection="column" alignItems="center" width={343}>
      <Typography variant="body2" color="Primary Blue" mr={1}>
        การจองคิวของคุณถูกยกเลิกแล้ว
      </Typography>
    </Box>
    <ButtonContainer>
      <Box mx={4}>
        <Button
          height={56}
          mb={2}
          fullWidth
          color="Primary"
          onClick={props.gotoBookingList}
        >
          กลับสู่หน้าหลัก
        </Button>

        <Button
          height={56}
          mb={4}
          fullWidth
          color="Secondary"
          onClick={props.gotoTaskList}
        >
          ไปหน้างานของฉัน
        </Button>
      </Box>
    </ButtonContainer>
  </Box>
);

const API = {};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withHooks((props, hooks) => {
    const { useCallback, useParams } = hooks;
    const { id } = useParams();
    const gotoFishDemand = useCallback(
      () => paths.bookingsPath().push("#fish_demand"),
      []
    );
    const gotoBookingList = useCallback(
      () => paths.bookingsPath().push("#fish_demand"),
      []
    );

    const gotoTaskList = useCallback(
      () => paths.bookingsPath().push("#booking"),
      []
    );

    return { gotoFishDemand, gotoBookingList, gotoTaskList };
  })
);

export default enhancer(CancelBookingPage);

import React, { useEffect } from "react";
import { compose, withHooks } from "enhancers";
import { useQuery, gql } from "@apollo/client";
import paths from "routes/paths";

import appStore from "stores/appStore";

import { Switch, Route, Redirect } from "components";

import SignInPage from "pages/auth/SignIn";

import BookingIndexPage from "pages/main/booking/index";
import BookingNewPage from "pages/main/booking/new";
import BookingEditPage from "pages/main/booking/edit";
import CancelBookingPage from "pages/main/booking/CancelBooking";

// prettier-ignore
const InitialPages = () => (
  // <BlankLayout>
  <>
    <Switch>
      <Route path={paths.signInPath()}                 exact                      component={SignInPage}              /> 
      <Redirect to={paths.signInPath()} />
    </Switch>
  </>
  // </BlankLayout>
)

// prettier-ignore
const MainPages = () => (
  <Switch>
    <Route path={paths.bookingsPath()}               exact                      component={BookingIndexPage}       />
    <Route path={paths.bookingNewPath()}             exact                      component={BookingNewPage} />
    <Route path={paths.bookingEditPath(":id")}       exact                      component={BookingEditPage} />
    <Route path={paths.cancelBookingPath(":id")}     exact                      component={CancelBookingPage}       />

    <Redirect to={paths.bookingsPath()} />
  </Switch>
);

interface RoutesProps {
  initialized: boolean;
  isAuthorized: boolean;
}

// prettier-ignore
const Routes = (props: RoutesProps) => {
  if (!props.initialized)           { return null;           }
  else  if (!props.isAuthorized)    { return <InitialPages />; }
  else                              { return <MainPages />;  }
};

export const API = {
  GET_APP_INFO: gql`
    query GET_APP_INFO {
      info {
        hasFirstAdmin
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentAppUser {
        id
        phoneNumber
        ownerData {
          type
          fisherman {
            id
            name
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useState } = hooks;
    const [isReady, setIsReady] = useState(false);
    const currentUser = useQuery(API.GET_CURRENT_USER, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.currentAppUser);
        setIsReady(true);
      },
      onError: () => {
        appStore.setCurrentUser(null);
        setIsReady(true);
      },
    });

    const initialized = isReady;
    const isAuthorized =
      !currentUser.error && currentUser?.data?.currentAppUser;

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen");
      if ($splashScreen) {
        const display = initialized ? "none" : "";
        $splashScreen.style.display = display;
      }
    }, [initialized]);

    return {
      initialized,
      isAuthorized,
    };
  })
);

export default enhancer(Routes);
